import React from 'react'
import { UserRound } from 'lucide-react'
import { Link } from 'react-router-dom'
import { useTheme } from '../../context/ThemeContext'
import { useAppData } from '../../context/ApplicationContext'
import dayjs from 'dayjs';

function Dashboard() {

  const { theme } = useTheme();

  const { instruktori, reservations, pricePerClass, klijenti } = useAppData();

  const calcTimeDifference = (startTime, endTime) => {
    if (!startTime || !endTime) {
      console.error("Invalid time input", { startTime, endTime });
      return 0; // Default value if input is invalid
    }

    // Split the time strings and convert to numbers
    const [startHours, startMinutes, startSeconds] = startTime.split(":").map(Number);
    const [endHours, endMinutes, endSeconds] = endTime.split(":").map(Number);

    // Convert everything to total minutes for easier calculation
    const startTotalMinutes = startHours * 60 + startMinutes + startSeconds / 60;
    const endTotalMinutes = endHours * 60 + endMinutes + endSeconds / 60;

    // Calculate the total difference in minutes
    const totalMinutesDifference = endTotalMinutes - startTotalMinutes;

    // Convert back to hours and fractions of hours
    const hours = Math.floor(totalMinutesDifference / 60);
    const minutes = totalMinutesDifference % 60;

    return hours + minutes / 60; // Return the difference in hours with fractions
  };

  return (
    <div className='p-3'>
      <table className={`w-full border ${theme === 'light' ? null : "border-gray-500"}`}>
        <thead className='w-full'>
          <tr className={`border-b ${theme === 'light' ? null : "border-gray-500"}`}>
            <th className={`p-2 font-normal text-left border-r text-sm ${theme === 'light' ? null : "border-gray-500"}`}>Instruktor</th>
            <th className={`p-2 font-normal text-left border-r text-sm ${theme === 'light' ? null : "border-gray-500"}`}>Datum i vrijeme</th>
            <th className={`p-2 font-normal text-left border-r text-sm ${theme === 'light' ? null : "border-gray-500"}`}>Učenik</th>
            <th className={`p-2 font-normal text-left border-r text-sm ${theme === 'light' ? null : "border-gray-500"}`}>Broj mobitela</th>
            <th className={`p-2 font-normal text-left border-r text-sm ${theme === 'light' ? null : "border-gray-500"}`}>Nivo skijanja</th>
            <th className={`p-2 font-normal text-left border-r text-sm ${theme === 'light' ? null : "border-gray-500"}`}>Oprema</th>
            <th className={`p-2 font-normal text-left border-r text-sm ${theme === 'light' ? null : "border-gray-500"}`}>Vrsta rezervacije</th>
            <th className={`p-2 font-normal text-left border-r text-sm ${theme === 'light' ? null : "border-gray-500"}`}>Iznos</th>
            <th className={`p-2 font-normal text-left border-r text-sm ${theme === 'light' ? null : "border-gray-500"}`}>Status</th>
          </tr>
        </thead>
        <tbody>
          {reservations?.map((rez) => {
            const calcHours = calcTimeDifference(rez?.vrijeme_pocetka, rez?.vrijeme_zavrsetka);
            const instruktor = instruktori?.find((instr) => instr.id === rez.id_instruktora);
            const klijent = klijenti?.find((klijent) => klijent.id === rez?.korisnik_id);

            return (
              <tr key={rez.id} className={`border-b ${theme === 'light' ? null : "border-gray-500"}`}>
                <td className={`text-left hover:${theme === "light" ? "bg-gray-200" : "bg-blue-500"} border-r ${theme === 'light' ? null : "border-gray-500"} text-sm`}>
                  <Link to={`/client/instructors/instructor/${instruktor?.id_instruktora}`} className='flex items-center gap-2 w-full h-full p-2 capitalize'>
                    <div className='p-2 rounded-full bg-gray-100 bg-opacity-15'><UserRound size={12} /></div>
                    {instruktor?.ime_prezime || 'Nepoznat instruktor'}
                  </Link>
                </td>
                <td className={`p-2 text-left border-r ${theme === 'light' ? null : "border-gray-500"} text-sm`}>{dayjs(rez.datum_rezervacije).format("DD.MM.YYYY")} {rez?.vrijeme_pocetka.replace(":00", "")}</td>
                <td className={`text-left hover:${theme === "light" ? "bg-gray-200" : "bg-blue-500"} border-r ${theme === 'light' ? null : "border-gray-500"} text-sm`}>
                  <Link className='flex items-center gap-2 w-full h-full p-2 capitalize'>
                    <div className='p-2 rounded-full bg-gray-100 bg-opacity-15'><UserRound size={12} /></div>
                    {rez?.ime_prezime || klijent?.ime_prezime || 'Nepoznat učenik'}
                  </Link>
                </td>
                <td className={`p-2 text-left border-r ${theme === 'light' ? null : "border-gray-500"} text-sm`}>
                  {rez?.broj_mobitela || klijent?.phone || 'Nepoznat broj'}
                </td>
                <td className={`p-2 text-left border-r ${theme === 'light' ? null : "border-gray-500"} text-sm capitalize`}>
                  {rez?.nivo_skijanja || klijent?.nivo_skijanja || 'Nepoznat nivo'}
                </td>
                <td className={`p-2 text-left border-r ${theme === 'light' ? null : "border-gray-500"} text-sm capitalize`}>
                  {rez?.oprema || klijent?.oprema || 'Nepoznata oprema'}
                </td>
                <td className={`p-2 text-left border-r ${theme === 'light' ? null : "border-gray-500"} text-sm`}>{rez?.reservation_type}</td>
                <td className={`p-2 text-left border-r ${theme === 'light' ? null : "border-gray-500"} text-sm`}>
                  {(pricePerClass.filter((ppc) => ppc.title.toLowerCase() === rez?.reservation_type.toLowerCase())
                    .map((ppc) => ppc.price) * calcHours).toLocaleString(void 0, { maximumFractionDigits: 2 })}
                  <span className='ml-3'>EUR</span>
                </td>
                <td className='p-2 text-center text-sm bg-green-100 text-green-600'>Završeno</td>
              </tr>
            );
          })}
        </tbody>
      </table>

    </div>
  )
}

export default Dashboard