import React from 'react';
import { useTheme } from '../../../context/ThemeContext';

function PrivacyPolicy() {
    const { theme } = useTheme();

    return (
        <div className={`min-h-screen py-20 px-6 ${theme === 'dark' ? 'bg-[#1a1a1a] text-white' : 'bg-gray-100 text-gray-800'}`}>
            <div className='container mx-auto'>
                <h1 className='text-4xl font-bold mb-8'>Politika privatnosti</h1>
                <p className='mb-4'>
                    Poštujemo vašu privatnost i posvećeni smo zaštiti ličnih podataka. Ova politika objašnjava kako prikupljamo, koristimo i štitimo vaše podatke.
                </p>
                <p className='mb-4'>
                    ... (Detaljna politika privatnosti ovdje) ...
                </p>
            </div>
        </div>
    );
}

export default PrivacyPolicy;
