import React, { createContext, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    fetchSchool,
    fetchRental,
    fetchReservations,
    fetchEquipment,
    fetchInstructors,
    fetchPricesPerClass,
    fetchKlijenti,
    createReservation,
    createEquipment,
    createRentedEquipment,
    fetchRentedEquipment,
    fetchRentedEquipmentItems,
    fetchRentals,
    fetchSchools,
    fetchOprema,
    fetchAllInstructors,
    fetchAllReservations
} from '../store/actions';
import Cookies from 'js-cookie';

const ApplicationContext = createContext();

export const ApplicationProvider = ({ children }) => {

    const [ startTime, setStartTime ] = useState('')
    const [ endTime, setEndTime ] = useState('')
    const [ reservationDate, setReservationDate ] = useState('')
    const [ instructorId, setInstructorId ] = useState(null)

    const dispatch = useDispatch();
    const {
        school,
        rental,
        reservations,
        pricePerClass,
        equipment,
        instruktori,
        klijenti,
        isLoading,
        isError,
        rentedEquipment,
        rentedEquipmentItems,
        rentals,
        schools,
        oprema,
        allInstructors,
        allReservations
    } = useSelector(state => state.app);

    // Fetch School and Rental data in parallel
    useEffect(() => {
        const fetchData = async () => {
            try {
                // Dispatch both actions in parallel
                await Promise.all([
                    dispatch(fetchSchool()),
                    dispatch(fetchRental()),
                    dispatch(fetchKlijenti()),
                    dispatch(fetchRentals()),
                    dispatch(fetchSchools()),
                    dispatch(fetchOprema()),
                    dispatch(fetchAllInstructors()),
                    dispatch(fetchAllReservations()),
                    dispatch(fetchPricesPerClass()),
                ]);
            } catch (error) {
                console.error('Error fetching school and rental data:', error);
            }
        };
        fetchData();
    }, [dispatch]);

    useEffect(() => {
        if (school?.id) {
            const fetchAdditionalData = async () => {
                try {
                    // Fetch all additional data in parallel
                    await Promise.all([
                        dispatch(fetchReservations(school.id)),
                        dispatch(fetchInstructors(school.id)),
                    ]);
                } catch (error) {
                    console.error('Error fetching reservations, equipment, and instructors:', error);
                }
            };
            fetchAdditionalData();
        }

        if (rental?.id) {
            const fetchAdditionalData = async () => {
                try {
                    // Fetch all additional data in parallel
                    await Promise.all([
                        dispatch(fetchEquipment(rental.id)),
                        dispatch(fetchRentedEquipment(rental.id)),
                    ]);
                } catch (error) {
                    console.error('Error fetching reservations, equipment, and instructors:', error);
                }
            };
            fetchAdditionalData();
        }
    }, [school?.id, rental?.id, dispatch]);  // Dependencies ensure this runs only when school and rental are available

    console.log(allInstructors)

    // Function to create a new reservation
    const handleCreateReservation = (reservationData) => {
        return dispatch(createReservation(reservationData));
    };

    const handleCreateEquipment = (equipmentData) => {
        return dispatch(createEquipment(equipmentData));
    }

    const handleCreateRentEquipment = (equipmentData) => {
        return dispatch(createRentedEquipment(equipmentData))
    }

    const handleLogOut = () => {
        Cookies.remove('token');
        Cookies.remove('userId');
        window.location.href = '/';
    }

    if (isLoading) return <div>Loading...</div>;
    if (isError) return <div>{isError}</div>;

    return (
        <ApplicationContext.Provider
            value={{
                isLoading,
                isError,
                reservations,
                pricePerClass,
                school,
                instruktori,
                klijenti,
                rental,
                equipment,
                handleCreateReservation,
                handleCreateEquipment,
                handleCreateRentEquipment,
                handleLogOut,
                rentedEquipment,
                rentedEquipmentItems,
                rentals,
                schools,
                oprema,
                allInstructors,
                allReservations,
                setStartTime,
                setEndTime,
                setReservationDate,
                startTime,
                endTime,
                reservationDate,
                instructorId, 
                setInstructorId
            }}
        >
            {children}
        </ApplicationContext.Provider>
    );
};

export const useAppData = () => {
    return useContext(ApplicationContext);
};
