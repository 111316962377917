import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Moon, Sun } from 'lucide-react';
import { useTheme } from '../context/ThemeContext';
import logoLight from '../assets/logo/skiberg.png';  // Assuming this is the light theme logo
import logoDark from '../assets/logo/skiberg-light.png';  // Dark theme logo
import '../theme.css'
import Footer from '../components/Footer'

function MainLayout({ children }) {

    const { theme, toggleTheme } = useTheme();

    const location = useLocation();

    const isActive = (path) => location.pathname.includes(path);

    return (
        <div className={`flex flex-col h-screen ${theme}`}>
            <header className="application-header flex items-center justify-between" style={{ minHeight: 60 }}>
                <img src={theme === 'dark' ? logoDark : logoLight} alt="Logo" />

                <nav className="h-full flex">
                    <button className='px-5' onClick={toggleTheme}>
                        {theme === "light" ? <Moon /> : <Sun />}
                    </button>

                    <Link
                        to="/"
                        className={`text-sm header-btn h-full flex items-center justify-center px-4 ${isActive('/') ? 'bg-green-600' : ''}`}
                    >
                        Početna
                    </Link>

                    <Link
                        to="/rezervisi"
                        className={`text-sm header-btn h-full flex items-center justify-center px-4 ${isActive('/rezerviši') ? 'bg-red-600 text-white' : ''}`}
                    >
                        Rezerviši
                    </Link>

                    <Link
                        to="/rental"
                        className={`text-sm header-btn h-full flex items-center justify-center px-4 ${isActive('/profil') ? 'bg-yellow-500' : ''}`}
                    >
                        Moj profil
                    </Link>
                </nav>
            </header>

            <main className={`flex-1 application-page`}>
                {children}
            </main>
            <Footer />
        </div>
    );
}

export default MainLayout;
