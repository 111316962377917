// src/store/middleware/authMiddleware.js
import { useHistory } from 'react-router-dom';
import Cookies from 'js-cookie';

const authMiddleware = (store) => (next) => (action) => {
    const userId = Cookies.get('userId');
    const token = Cookies.get('token');

    // Check if the action type is one of the routes you want to protect
    const protectedRoutes = ['/client', '/rental'];
    const currentPath = window.location.pathname;

    if (protectedRoutes.includes(currentPath) && (!userId || !token)) {
        // If no userId or token, redirect to login
        alert('You must be logged in to access this page.');
        window.location.href = '/login'; // Redirect to your login page
    }

    return next(action);
};

export default authMiddleware;
