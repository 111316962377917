// src/App.js
import React from 'react';
import { ApplicationProvider } from './context/ApplicationContext';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Authentication from './paths/Authentication';
import CRouter from './paths/client/CRouter';
import MainRouter from './paths/main/MainRouter';
import RentRouter from './paths/rent/RentRouter';
import ProtectedRoute from './components/ProtectedRoute';

function App() {
  return (
    <ApplicationProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/*" element={<MainRouter />} />
          <Route path="/authentication" element={<Authentication />} />

          <Route
            path="/client/*"
            element={
              <ProtectedRoute>
                <CRouter />
              </ProtectedRoute>
            }
          />

          <Route
            path="/rental/*"
            element={
              <ProtectedRoute>
                <RentRouter />
              </ProtectedRoute>
            }
          />
        </Routes>
      </BrowserRouter>
    </ApplicationProvider>
  );
}

export default App;
