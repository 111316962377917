import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { ThemeProvider } from '../../context/ThemeContext'
import RentLayout from '../../layout/RentLayout'
import Dashboard from './Dashboard'
import Oprema from './Oprema'
import Postavke from './Postavke'
import RentanaOprema from './RentanaOprema'
import EquipmentCalendar from './EquipmentCalendar'

function RentRouter() {
    return (
        <ThemeProvider>
            <RentLayout>
                <Routes>
                    <Route path='/' element={<Dashboard />} />
                    <Route path='/oprema' element={<Oprema />} />
                    <Route path='/postavke' element={<Postavke />} />
                    <Route path='/iznajmljena-oprema' element={<RentanaOprema />} />
                    <Route path='/kalendar-iznajmljene-oprema' element={<EquipmentCalendar />} />
                </Routes>
            </RentLayout>
        </ThemeProvider>
    )
}

export default RentRouter