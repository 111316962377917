import React from 'react';
import { useTheme } from '../../../context/ThemeContext';

function TermsConditions() {
    const { theme } = useTheme();

    return (
        <div className={`min-h-screen py-20 px-6 ${theme === 'dark' ? 'bg-[#1a1a1a] text-white' : 'bg-gray-100 text-gray-800'}`}>
            <div className='container mx-auto'>
                <h1 className='text-4xl font-bold mb-8'>Uvjeti i odredbe</h1>
                <p className='mb-4'>
                    Ovi uvjeti i odredbe reguliraju vašu upotrebu usluga i sadržaja na našoj platformi. Korištenjem naše platforme, slažete se s uvjetima.
                </p>
                <p className='mb-4'>
                    ... (Detaljni uvjeti i odredbe ovdje) ...
                </p>
            </div>
        </div>
    );
}

export default TermsConditions;
