import React, { useEffect, useState } from 'react';
import { useTheme } from '../../context/ThemeContext';
import { da } from 'date-fns/locale';
import { Trash2, X } from 'lucide-react';
import { useAppData } from '../../context/ApplicationContext';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';

const reservationsData = [
    {
        id: 1,
        equipmentId: 1,
        skierName: "Jovan Mirković",
        startDate: "2024-11-10",
        endDate: "2024-11-11",
        totalDays: 1
    },
    {
        id: 2,
        equipmentId: 1,
        skierName: "Bojana Mirković",
        startDate: "2024-12-10",
        endDate: "2024-12-13",
        totalDays: 3
    },
    {
        id: 3,
        equipmentId: 1,
        skierName: "Marko Marković",
        startDate: "2024-14-10",
        endDate: "2024-14-18",
        totalDays: 8
    }
]

function Oprema() {

    const { theme } = useTheme();
    const { equipment, rental, handleCreateEquipment, handleCreateRentEquipment } = useAppData();

    const [openModal, setOpenModal] = useState(false);

    const [newEquipment, setNewEquipment] = useState({
        rental_id: null,
        equipmentNumber: null,
        name: '',
        brand: '',
        type: '',
        length_size: '',
        equpiment_condition: '',
        price_per_day: '',
        weight: null,
        year_of_manufacture: '',
        level_of_skier: '',
        age_group: '',
        quantity: null,
        description: '',
    });

    const [reservationDetails, setReservationDetails] = useState({
        rental_id: null,
        skier_name: '',
        phone: '',
        start_date: '',
        end_date: '',
        price: null,
        equipment_item_ids: []
    })

    const [cart, setCart] = useState([]);
    const [selectedEquipment, setSelectedEquipment] = useState(null)
    const [selectedItem, setSelectedItem] = useState(null);

    const [condition, setCondition] = useState("");
    const [available, setAvailable] = useState(null);
    const [brandSearch, setBrandSearch] = useState("");
    const [sizeSearch, setSizeSearch] = useState("");
    const [numberSearch, setNumberSearch] = useState(null);

    const [reservations, setReservations] = useState([]);


    const handleAddToCart = (item) => {
        const currItem = equipment.filter((equip) => equip.id === item)[0];

        if (currItem.quantity - currItem.reservedQuantity > 0) {
            setCart((prev) => [...prev, currItem]);

            // Append the item ID to equipment_item_ids array
            setReservationDetails(prev => ({
                ...prev,
                equipment_item_ids: [...prev.equipment_item_ids, currItem.id]
            }));

            setSelectedItem(currItem);
            currItem.reservedQuantity += 1;
        } else {
            alert("Oprema nije dostupna!");
        }
    };

    const handleReserve = () => {
        if (cart.length > 0) {
            setReservations((prev) => [...prev, ...cart]);
            cart.forEach(item => {
                item.quantity -= 1;
                item.reservedQuantity += 1;
            });
            setCart([]);
            alert("Rezervacija uspešna!");
        } else {
            alert("Korpa je prazna!");
        }
    };


    const calculateTotal = () => {
        const start = dayjs(reservationDetails.start_date);
        const end = dayjs(reservationDetails.end_date);

        if (end.isBefore(start)) {
            console.error("End date cannot be before start date");
            return { total: 0, finalTotal: 0 };
        }

        const difference = end.diff(start, 'day') || 1; 

        const total = cart.reduce((acc, item) => {
            if (item.price_per_day) {
                return acc + Number(item.price_per_day); // Ensure item.price_per_day is defined
            }
            return acc; // If not defined, just return acc
        }, 0);

        // Calculate final total based on the number of days
        const finalTotal = total * difference;

        return { total, finalTotal };
    };


    const filteredOprema = equipment.filter((equipment) => {
        const matchesCondition = condition ? equipment.equpiment_condition === condition : true;
        const matchesAvailability = available !== null ? equipment.quantity - equipment.reservedQuantity > 0 === available : true;
        const matchesBrand = equipment.brand?.toLowerCase().includes(brandSearch.toLowerCase());
        const matchesSize = equipment.length_size?.toLowerCase().includes(sizeSearch.toLowerCase());
        const matchesNumber = numberSearch ? String(equipment.equipmentNumber)?.toLowerCase().includes(String(numberSearch).toLowerCase()) : true

        return matchesCondition && matchesAvailability && matchesBrand && matchesSize && matchesNumber;
    });

    const { total, finalTotal } = calculateTotal();

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewEquipment(prev => ({ ...prev, [name]: value }));
    };

    const handleAddEquipment = () => {
        console.log('Adding new equipment:', newEquipment);
        setOpenModal(false);
    };

    const generateRandomNumber = () => {
        return Math.floor(100000 + Math.random() * 900000);
    };

    useEffect(() => {
        const randomNumber = generateRandomNumber();
        setNewEquipment(prevFormData => ({
            ...prevFormData,
            equipmentNumber: Number(randomNumber)
        }));
        if(rental.id) {
            setNewEquipment(prevFormData => ({
               ...prevFormData,
                rental_id: Number(rental.id)
            }));
            setReservationDetails(prev => ({
                ...prev,
                rental_id: Number(rental.id)
            }))
        }
        if(finalTotal) {
            setReservationDetails(prev => ({
                ...prev,
                price: Number(finalTotal)
            }))
        }
    }, [rental?.id, finalTotal]);

    console.log(reservationDetails)

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            toast.success('Uspiješno kreirana oprema!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light"
            });
            await handleCreateEquipment(newEquipment);
            setTimeout(() => {
                window.location.reload();
            }, 5000)
        } catch (error) {
            console.error('Error creating reservation:', error);
        }
    }

    const handleSubmitRent = async (e) => {
        e.preventDefault();
        try {
            toast.success('Uspiješno rezervisana oprema !', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light"
            });
            await handleCreateRentEquipment(reservationDetails);
        } catch (error) {
            console.error('Error creating reservation:', error);
        }
    }

    console.log(reservationDetails)

    return (
        <div className="w-full p-4">
            <div className={`w-full`}>

                {openModal && (
                    <div className="fixed inset-0 flex items-center justify-center z-50 bg-white bg-opacity-50 backdrop:blur-lg px-4">
                        <div className="bg-white rounded shadow-lg p-6 w-full sm:w-2/4 border">
                            <h2 className="text-lg font-bold mb-4">Dodaj novu opremu</h2>
                            <div className="grid grid-cols-1 gap-2">
                                <input
                                    className="border p-2 mb-2 outline-none text-sm"
                                    type="text"
                                    name="rental_id"
                                    placeholder="ID RENTALA"
                                    value={newEquipment.rental_id}
                                    disabled
                                    onChange={handleInputChange}
                                />
                                <input
                                    className="border p-2 mb-2 outline-none text-sm"
                                    type="text"
                                    name="equipmentNumber"
                                    placeholder="Broj opreme"
                                    value={newEquipment.equipmentNumber}
                                    disabled
                                    onChange={handleInputChange}
                                />
                                <input
                                    className="border p-2 mb-2 outline-none text-sm"
                                    type="text"
                                    name="name"
                                    placeholder="Naziv"
                                    value={newEquipment.name}
                                    onChange={handleInputChange}
                                />
                                <input
                                    className="border p-2 mb-2 outline-none text-sm"
                                    type="text"
                                    name="brand"
                                    placeholder="Brend"
                                    value={newEquipment.brand}
                                    onChange={handleInputChange}
                                />
                                <div className="w-full grid grid-cols-2 sm:grid-cols-3 gap-3 mb-2">
                                    <button onClick={() => setNewEquipment(prev => ({ ...prev, type: "Skije" }))} className={` flex items-center justify-between p-3 gap-3 rounded-lg bg-white border ${newEquipment.type === "Skije" ? 'border-blue-500' : null} `}>
                                        <span>Skije</span>
                                        <div style={{ width: 26, height: 26 }} className={` rounded-full ${newEquipment.type === "Skije" ? "bg-blue-500" : "bg-gray-100"} `} ></div>
                                    </button>
                                    <button onClick={() => setNewEquipment(prev => ({ ...prev, type: "Snowboard" }))} className={` flex items-center justify-between p-3 gap-3 rounded-lg bg-white border ${newEquipment.type === "Snowboard" ? 'border-blue-500' : null} `}>
                                        <span>Snowboard</span>
                                        <div style={{ width: 26, height: 26 }} className={` rounded-full ${newEquipment.type === "Snowboard" ? "bg-blue-500" : "bg-gray-100"} `} ></div>
                                    </button>
                                    <button onClick={() => setNewEquipment(prev => ({ ...prev, type: "Pancerice" }))} className={` flex items-center justify-between p-3 gap-3 rounded-lg bg-white border ${newEquipment.type === "Pancerice" ? 'border-blue-500' : null} `}>
                                        <span>Pancerice</span>
                                        <div style={{ width: 26, height: 26 }} className={` rounded-full ${newEquipment.type === "Pancerice" ? "bg-blue-500" : "bg-gray-100"} `} ></div>
                                    </button>
                                    <button onClick={() => setNewEquipment(prev => ({ ...prev, type: "Štapovi" }))} className={` flex items-center justify-between p-3 gap-3 rounded-lg bg-white border ${newEquipment.type === "Štapovi" ? 'border-blue-500' : null} `}>
                                        <span>Štapovi</span>
                                        <div style={{ width: 26, height: 26 }} className={` rounded-full ${newEquipment.type === "Štapovi" ? "bg-blue-500" : "bg-gray-100"} `} ></div>
                                    </button>
                                    <button onClick={() => setNewEquipment(prev => ({ ...prev, type: "Kacige" }))} className={` flex items-center justify-between p-3 gap-3 rounded-lg bg-white border ${newEquipment.type === "Kacige" ? 'border-blue-500' : null} `}>
                                        <span>Kacige</span>
                                        <div style={{ width: 26, height: 26 }} className={` rounded-full ${newEquipment.type === "Kacige" ? "bg-blue-500" : "bg-gray-100"} `} ></div>
                                    </button>
                                    <button onClick={() => setNewEquipment(prev => ({ ...prev, type: "Dodatna oprema" }))} className={` flex items-center justify-between p-3 gap-3 rounded-lg bg-white border ${newEquipment.type === "Dodatna oprema" ? 'border-blue-500' : null} `}>
                                        <span>Dodatna oprema</span>
                                        <div style={{ width: 26, height: 26 }} className={` rounded-full ${newEquipment.type === "Dodatna oprema" ? "bg-blue-500" : "bg-gray-100"} `} ></div>
                                    </button>
                                </div>
                                <input
                                    className="border p-2 mb-2 outline-none text-sm"
                                    type="text"
                                    name="length_size"
                                    placeholder="Dimenzije ( cm ili broj )"
                                    value={newEquipment.length_size}
                                    onChange={handleInputChange}
                                />
                                <div className="w-full grid grid-cols-2 sm:grid-cols-3 gap-3 mb-2">
                                    <button onClick={() => setNewEquipment(prev => ({ ...prev, equpiment_condition: "Novo" }))} className={` flex items-center justify-between p-3 gap-3 rounded-lg border bg-white ${newEquipment.equpiment_condition === "Novo" ? 'border-blue-500' : 'bg-white'} `}>
                                        <span>Novo</span>
                                        <div style={{ width: 26, height: 26 }} className={` rounded-full ${newEquipment.equpiment_condition === "Novo" ? "bg-blue-500" : "bg-gray-100"} `} ></div>
                                    </button>
                                    <button onClick={() => setNewEquipment(prev => ({ ...prev, equpiment_condition: "Korišteno" }))} className={` flex items-center justify-between p-3 gap-3 rounded-lg border bg-white ${newEquipment.equpiment_condition === "Korišteno" ? 'border-blue-500' : 'bg-white'} `}>
                                        <span>Korišteno</span>
                                        <div style={{ width: 26, height: 26 }} className={` rounded-full ${newEquipment.equpiment_condition === "Korišteno" ? "bg-blue-500" : "bg-gray-100"} `} ></div>
                                    </button>
                                    <button onClick={() => setNewEquipment(prev => ({ ...prev, equpiment_condition: "Oštećeno" }))} className={` flex items-center justify-between p-3 gap-3 rounded-lg border bg-white ${newEquipment.equpiment_condition === "Oštećeno" ? 'border-blue-500' : 'bg-white'} `}>
                                        <span>Oštećeno</span>
                                        <div style={{ width: 26, height: 26 }} className={` rounded-full ${newEquipment.equpiment_condition === "Oštećeno" ? "bg-blue-500" : "bg-gray-100"} `} ></div>
                                    </button>
                                </div>
                                <input
                                    className="border p-2 mb-2 outline-none text-sm"
                                    type="number"
                                    name="price_per_day"
                                    placeholder="Cijena (po danu)"
                                    value={Number(newEquipment.price_per_day)}
                                    onChange={(e) => setNewEquipment(prev => ({ ...prev, price_per_day: Number(e.target.value) }))}
                                />
                                <input
                                    className="border p-2 mb-2 outline-none text-sm"
                                    type="number"
                                    name="weight"
                                    placeholder="Težina"
                                    value={newEquipment.weight}
                                    onChange={handleInputChange}
                                />
                                <input
                                    className="border p-2 mb-2 outline-none text-sm"
                                    type="text"
                                    name="year_of_manufacture"
                                    placeholder="Godina proizvodnje"
                                    value={newEquipment.year_of_manufacture}
                                    onChange={handleInputChange}
                                />
                                <div className="w-full grid grid-cols-2 sm:grid-cols-4 gap-3 mb-2">
                                    <button onClick={() => setNewEquipment(prev => ({ ...prev, level_of_skier: "Početnik" }))} className={` flex items-center justify-between p-3 gap-3 rounded-lg border bg-white ${newEquipment.level_of_skier === "Početnik" ? 'border-blue-500' : 'bg-white'} `}>
                                        <span>Početnik</span>
                                        <div style={{ width: 26, height: 26 }} className={` rounded-full ${newEquipment.level_of_skier === "Početnik" ? "bg-blue-500" : "bg-gray-100"} `} ></div>
                                    </button>
                                    <button onClick={() => setNewEquipment(prev => ({ ...prev, level_of_skier: "Srednji" }))} className={` flex items-center justify-between p-3 gap-3 rounded-lg border bg-white ${newEquipment.level_of_skier === "Srednji" ? 'border-blue-500' : 'bg-white'} `}>
                                        <span>Srednji</span>
                                        <div style={{ width: 26, height: 26 }} className={` rounded-full ${newEquipment.level_of_skier === "Srednji" ? "bg-blue-500" : "bg-gray-100"} `} ></div>
                                    </button>
                                    <button onClick={() => setNewEquipment(prev => ({ ...prev, level_of_skier: "Iskusan" }))} className={` flex items-center justify-between p-3 gap-3 rounded-lg border bg-white ${newEquipment.level_of_skier === "Iskusan" ? 'border-blue-500' : 'bg-white'} `}>
                                        <span>Iskusan</span>
                                        <div style={{ width: 26, height: 26 }} className={` rounded-full ${newEquipment.level_of_skier === "Iskusan" ? "bg-blue-500" : "bg-gray-100"} `} ></div>
                                    </button>
                                    <button onClick={() => setNewEquipment(prev => ({ ...prev, level_of_skier: "Svi uzrasti" }))} className={` flex items-center justify-between p-3 gap-3 rounded-lg border bg-white ${newEquipment.level_of_skier === "Svi uzrasti" ? 'border-blue-500' : 'bg-white'} `}>
                                        <span>Svi uzrasti</span>
                                        <div style={{ width: 26, height: 26 }} className={` rounded-full ${newEquipment.level_of_skier === "Svi uzrasti" ? "bg-blue-500" : "bg-gray-100"} `} ></div>
                                    </button>
                                </div>
                                <div className="w-full grid grid-cols-3 gap-3 mb-2">
                                    <button onClick={() => setNewEquipment(prev => ({ ...prev, age_group: "Dijeca" }))} className={` flex items-center justify-between p-3 gap-3 rounded-lg border bg-white ${newEquipment.age_group === "Dijeca" ? 'border-blue-500' : 'bg-white'} `}>
                                        <span>Dijeca</span>
                                        <div style={{ width: 26, height: 26 }} className={` rounded-full ${newEquipment.age_group === "Dijeca" ? "bg-blue-500" : "bg-gray-100"} `} ></div>
                                    </button>
                                    <button onClick={() => setNewEquipment(prev => ({ ...prev, age_group: "Odrasli" }))} className={` flex items-center justify-between p-3 gap-3 rounded-lg border bg-white ${newEquipment.age_group === "Odrasli" ? 'border-blue-500' : 'bg-white'} `}>
                                        <span>Odrasli</span>
                                        <div style={{ width: 26, height: 26 }} className={` rounded-full ${newEquipment.age_group === "Odrasli" ? "bg-blue-500" : "bg-gray-100"} `} ></div>
                                    </button>
                                    <button onClick={() => setNewEquipment(prev => ({ ...prev, age_group: "Svi uzrasti" }))} className={` flex items-center justify-between p-3 gap-3 rounded-lg border bg-white ${newEquipment.age_group === "Svi uzrasti" ? 'border-blue-500' : 'bg-white'} `}>
                                        <span>Svi uzrasti</span>
                                        <div style={{ width: 26, height: 26 }} className={` rounded-full ${newEquipment.age_group === "Svi uzrasti" ? "bg-blue-500" : "bg-gray-100"} `} ></div>
                                    </button>
                                </div>
                                <input
                                    className="border p-2 mb-2 outline-none text-sm"
                                    type="number"
                                    name="quantity"
                                    placeholder="Količina"
                                    value={newEquipment.quantity}
                                    onChange={(e) => setNewEquipment(prev => ({ ...prev, quantity: Number(e.target.value) }))}
                                />
                                <textarea
                                    className="border p-2 mb-2 outline-none text-sm"
                                    name="description"
                                    placeholder="Opis"
                                    value={newEquipment.description}
                                    onChange={handleInputChange}
                                />
                                <div className="flex justify-end">
                                    <button onClick={handleSubmit} className="bg-blue-500 text-white px-4 py-2 rounded">Dodaj</button>
                                    <button onClick={() => setOpenModal(false)} className="ml-2 border px-4 py-2 rounded">Zatvori</button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                <div className='mb-4 flex items-center justify-between'>
                    <div className='flex items-center gap-3'>
                        <h2 className="text-xl font-bold opacity-75">MOJA OPREMA</h2>
                        <button className='text-sm p-2' onClick={() => setOpenModal(true)}>Dodaj opremu</button>
                    </div>
                    <div className="equipment-filters flex gap-2">
                        <button onClick={() => setCondition(condition === "Novo" ? "" : "Novo")} className={`filter-btn text-xs ${condition === "Novo" ? "bg-blue-500 text-white" : null}`}>Novo</button>
                        <button onClick={() => setCondition(condition === "Korišteno" ? "" : "Korišteno")} className={`filter-btn text-xs ${condition === "Korišteno" ? "bg-blue-500 text-white" : null}`}>Korišteno</button>
                        <button onClick={() => setAvailable(available ? null : true)} className={`filter-btn text-xs ${available ? "bg-blue-500 text-white" : null}`}>Dostupno</button>
                        <input
                            className='text-xs outline-none search-input w-auto'
                            type="text"
                            placeholder="Pretražite po brendu"
                            value={brandSearch}
                            onChange={(e) => setBrandSearch(e.target.value)}
                        />
                        <input
                            className='text-xs outline-none search-input w-auto'
                            type="text"
                            placeholder="Pretražite po veličini"
                            value={sizeSearch}
                            onChange={(e) => setSizeSearch(e.target.value)}
                        />
                        <input

                            className='text-xs outline-none search-input '
                            type="number"
                            placeholder="Pretražite po broju"
                            value={numberSearch}
                            onChange={(e) => setNumberSearch(e.target.value)}
                        />
                    </div>
                </div>

                {/* Tabela opreme */}
                <div className="overflow-x-auto">
                    <table className="min-w-full border">
                        <thead className={`${theme === 'light' ? 'bg-gray-200' : 'bg-[#555]'}`}>
                            <tr className='border-b'>
                                <th className="p-2 text-xs text-left font-medium opacity-50">Broj</th>
                                <th className="p-2 text-xs text-left font-medium opacity-50">Naziv</th>
                                <th className="p-2 text-xs text-left font-medium opacity-50">Brend</th>
                                <th className="p-2 text-xs text-left font-medium opacity-50">Tip opreme</th>
                                <th className="p-2 text-xs text-left font-medium opacity-50">Dimenzije</th>
                                <th className="p-2 text-xs text-left font-medium opacity-50">Stanje opreme</th>
                                <th className="p-2 text-xs text-left font-medium opacity-50">Cijena (po danu)</th>
                                <th className="p-2 text-xs text-center font-medium opacity-50">Težina</th>
                                <th className="p-2 text-xs text-center font-medium opacity-50">Godina proizvodnje</th>
                                <th className="p-2 text-xs text-left font-medium opacity-50">Nivo skijaša</th>
                                <th className="p-2 text-xs text-left font-medium opacity-50">Starosna grupa</th>
                                <th className="p-2 text-xs text-left font-medium opacity-50">Količina</th>
                                <th className="p-2 text-xs text-left font-medium opacity-50">Preostalo</th>
                                <th className="p-2 text-xs text-left font-medium opacity-50">Akcija</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredOprema.map((equipment) => (
                                <tr key={equipment.id} className={`border-b`}>
                                    <td onClick={() => setSelectedEquipment(equipment.id)} className="p-2 border-r text-xs cursor-pointer hover:bg-blue-500 hover:text-white">{equipment.equipmentNumber}</td>
                                    <td className="p-2 border-r text-xs">{equipment.name}</td>
                                    <td className="p-2 border-r text-xs">{equipment.brand}</td>
                                    <td className="p-2 border-r text-xs">{equipment.type}</td>
                                    <td className="p-2 border-r text-xs">{equipment.length_size}</td>
                                    <td className="p-2 border-r text-xs">{equipment.equpiment_condition}</td>
                                    <td className="p-2 border-r text-xs">{equipment.price_per_day} EUR</td>
                                    <td className="p-2 border-r text-center text-xs">{equipment.weight ? `${equipment.weight} g` : "-"}</td>
                                    <td className="p-2 border-r text-center text-xs">{equipment.year_of_manufacture}</td>
                                    <td className="p-2 border-r text-xs capitalize">{equipment.level_of_skier}</td>
                                    <td className="p-2 border-r text-xs capitalize">{equipment.age_group}</td>
                                    <td className="p-2 border-r text-xs">{equipment.quantity - equipment.reservedQuantity} / {equipment.quantity}</td>
                                    <td className={`p-2 border-r text-xs ${(equipment.quantity - equipment.reservedQuantity) > 0 ? 'bg-green-100 text-green-600' : 'bg-red-100 text-red-600'}`}>
                                        {(equipment.quantity - equipment.reservedQuantity) > 0 ? "Slobodna" : "Zauzeto"}
                                    </td>
                                    <td className="p-2 border-r text-xs">
                                        <button onClick={() => handleAddToCart(equipment.id)} className="bg-blue-500 text-white text-xs px-2 py-1 rounded">Dodaj u korpu</button>
                                    </td>

                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

            </div>

            <div className='grid grid-cols-1 md:grid-cols-2 gap-3 items-start'>
                {cart.length > 0 && (
                    <div className='mt-3 border rounded-lg'>
                        <div className='p-4 border-b grid grid-cols-2 gap-3'>
                            <input
                                className='text-sm outline-none bg-gray-100 p-4 rounded'
                                type="text"
                                placeholder="Unesite ime i prezime"
                                value={reservationDetails.skier_name}
                                onChange={(e) => setReservationDetails(prev => ({ ...prev, skier_name: e.target.value }))}
                            />
                            <input
                                className='text-sm outline-none bg-gray-100 p-4 rounded'
                                type="text"
                                placeholder="Unesite broj mobitel"
                                value={reservationDetails.phone}
                                onChange={(e) => setReservationDetails(prev => ({ ...prev, phone: e.target.value }))}
                            />
                            <input

                                className='text-sm outline-none bg-gray-100 p-4 rounded'
                                type="date"
                                value={reservationDetails.start_date}
                                onChange={(e) => setReservationDetails(prev => ({ ...prev, start_date: e.target.value }))}
                            />
                            <input

                                className='text-sm outline-none bg-gray-100 p-4 rounded'
                                type="date"
                                value={reservationDetails.end_date}
                                onChange={(e) => setReservationDetails(prev => ({ ...prev, end_date: e.target.value }))}
                            />
                        </div>
                        <div className="p-4 rounded-br-lg rounded-bl-lg">
                            <h3 className="text-lg font-bold pb-4 border-b">Korpa</h3>
                            <ul>
                                {cart.map((item, index) => (
                                    <li key={index} className="flex justify-between border-b py-2">
                                        <span>{item.name}</span>
                                        <span>{item.price_per_day} EUR</span>
                                    </li>
                                ))}
                            </ul>
                            <div className="flex justify-between mt-2 font-bold pb-2 border-b">
                                <span>Ukupno:</span>
                                <span>{finalTotal.toFixed(2)} EUR</span>
                            </div>
                            <div className='flex items-center gap-3 mt-3'>
                                <button className='bg-red-100 text-red-600 p-2 rounded text-sm' onClick={() => {
                                    setCart([])
                                    setReservations([])
                                }}><Trash2 /></button>
                                <button onClick={handleSubmitRent} className="bg-green-500 text-white px-4 py-2 rounded">Završi rezervaciju</button>
                            </div>

                        </div>
                    </div>
                )}

                {selectedEquipment && equipment.filter((oprema) => oprema.id === selectedEquipment).map((data) => {
                    return (
                        <div className="mt-3 p-5 rounded-lg border relative">

                            <button onClick={() => setSelectedEquipment(null)} className='p-3 rounded-lg absolute top-3 right-3 bg-red-100 text-red-600 flex items-center gap-3 text-sm'><X size={14} /> Otkaži</button>

                            <h2 className="text-2xl font-semibold mb-4">{data.name}</h2>

                            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-6">
                                <div className="flex flex-col">
                                    <span className="font-medium text-sm opacity-50">Marka:</span>
                                    <h3 className="text-xs">{data.brand}</h3>
                                </div>

                                <div className="flex flex-col">
                                    <span className="font-medium text-sm opacity-50">Tip:</span>
                                    <h3 className="text-xs">{data.type}</h3>
                                </div>

                                <div className="flex flex-col">
                                    <span className="font-medium text-sm opacity-50">Dužina:</span>
                                    <h3 className="text-xs">{data.length_size}</h3>
                                </div>

                                <div className="flex flex-col">
                                    <span className="font-medium text-sm opacity-50">Stanje:</span>
                                    <h3 className="text-xs">{data.equpiment_condition}</h3>
                                </div>

                                <div className="flex flex-col">
                                    <span className="font-medium text-sm opacity-50">Cijena po danu:</span>
                                    <h3 className="text-xs">{data.price_per_day} EUR</h3>
                                </div>

                                <div className="flex flex-col">
                                    <span className="font-medium text-sm opacity-50">Težina:</span>
                                    <h3 className="text-xs">{data.weight ? `${data.weight} g` : '-'}</h3>
                                </div>

                                <div className="flex flex-col">
                                    <span className="font-medium text-sm opacity-50">Godina proizvodnje:</span>
                                    <h3 className="text-xs">{data.year_of_manufacture} god.</h3>
                                </div>

                                <div className="flex flex-col">
                                    <span className="font-medium text-sm opacity-50">Nivo skijaša:</span>
                                    <h3 className="text-xs capitalize">{data.level_of_skier}</h3>
                                </div>

                                <div className="flex flex-col">
                                    <span className="font-medium text-sm opacity-50">Starosna grupa:</span>
                                    <h3 className="text-xs capitalize">{data.age_group}</h3>
                                </div>

                                <div className="flex flex-col">
                                    <span className="font-medium text-sm opacity-50">Količina:</span>
                                    <h3 className="text-xs">{data.quantity}</h3>
                                </div>

                                <div className="flex flex-col">
                                    <span className="font-medium text-sm opacity-50">Rezervisana količina:</span>
                                    <h3 className="text-xs">{data.reservedQuantity === null ? 0 : data.reservedQuantity}</h3>
                                </div>
                            </div>

                            {data.description && (
                                <div className="mt-6">
                                    <h3 className="font-medium text-sm opacity-50">Opis:</h3>
                                    <p className="mt-2 opacity-75 text-xs">{data.description}</p>
                                </div>
                            )}

                            <div className={`${data.reservedQuantity < data.quantity ? 'bg-green-100 text-green-600' : 'bg-red-100 text-red-600'} p-3 rounded-lg text-center mt-6 font-semibold`}>
                                {data.reservedQuantity < data.quantity ? "Dostupno" : "Nedostupno"}
                            </div>

                            {
                                reservationsData.filter((res) => res.equipmentId === data.id).length > 0 && (
                                    <table className='w-full mt-6 border'>
                                        <thead>
                                            <tr className='border-b bg-blue-500 text-white'>
                                                <th style={{ width: '30%' }} className='font-normal p-2 text-xs text-left border-r'>Ime i Prezime</th>
                                                <th style={{ width: '20%' }} className='font-normal p-2 text-xs text-left border-r'>Datum preuzimanja</th>
                                                <th style={{ width: '20%' }} className='font-normal p-2 text-xs text-left border-r'>Datum vraćanja</th>
                                                <th style={{ width: '10%' }} className='font-normal p-2 text-xs text-left border-r'>Trajanje</th>
                                                <th style={{ width: '20%' }} className='font-normal p-2 text-xs text-right border-r'>Iznos</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                reservationsData.filter((res) => res.equipmentId === data.id).map((res) => {
                                                    return (
                                                        <tr className={`border-b ${theme === 'light' ? 'hover:bg-gray-100' : 'hover:bg-[#444]'}`}>
                                                            <td className='font-normal p-2 text-xs text-left border-r'>{res.skierName}</td>
                                                            <td className='font-normal p-2 text-xs text-left border-r'>{res.startDate}</td>
                                                            <td className='font-normal p-2 text-xs text-left border-r'>{res.endDate}</td>
                                                            <td className='font-normal p-2 text-xs text-left border-r'>{res.totalDays} {res.totalDays > 1 ? "dana" : "dan"}</td>
                                                            <td className='font-semibold p-2 text-xs text-right border-r'>{res.totalDays * data.price_per_day} EUR</td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                )
                            }

                        </div>

                    )
                })}
            </div>
        </div>
    );
}

export default Oprema;
