import React, { useEffect, useState } from 'react';
import logoLight from '../../assets/logo/skiberg.png';
import logoDark from '../../assets/logo/skiberg-light.png';
import { useTheme } from '../../context/ThemeContext';

function Home() {

    const { theme } = useTheme();
    const [comments, setComments] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);


    useEffect(() => {
        const randomComments = [
            { name: "Jovana P.", comment: "Izvrsno iskustvo! Instruktori su bili fantastični i vrlo strpljivi.", rating: 5 },
            { name: "Marko D.", comment: "Ski tura je bila nevjerovatna! Preporučujem svima.", rating: 5 },
            { name: "Ana K.", comment: "Savršena usluga i oprema. Definitivno ćemo se vratiti!", rating: 4 },
            { name: "Luka T.", comment: "Moja djeca su obožavala instruktore. Preporučujem za porodice.", rating: 5 },
            { name: "Nikolina S.", comment: "Najbolji ski odmor ikad! Organizacija je bila vrhunska.", rating: 5 },
        ];

        const shuffledComments = randomComments.sort(() => 0.5 - Math.random()).slice(0, 3);
        setComments(shuffledComments);
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % (Math.ceil(comments.length / 3)));
        }, 4000);
        return () => clearInterval(interval);
    }, [comments]);

    const getCurrentComments = () => {
        const startIndex = currentIndex * 3;
        return comments.slice(startIndex, startIndex + 3);
    };

    return (
        <div className={`${theme === 'dark' ? 'bg-[#333]' : 'bg-gray-100'}`} style={{ minHeight: 'calc(100vh - 60)' }}>

            <div
                className='flex items-center justify-center'
                style={{
                    backgroundImage: `url("https://hotel-jahorina.com/wp-content/uploads/2023/12/JAHORINA_CAM_01-1.jpg")`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    height: 'calc(100vh - 60px)'
                }}
            >
                <div className={`flex items-center justify-center w-full h-full bg-opacity-50 ${theme === 'light' ? 'bg-gray-100' : 'bg-[#444]'}`}>
                    <img className='w-full md:w-1/3' src={theme === 'dark' ? logoDark : logoLight} alt="Logo" />
                </div>
            </div>

            <section className={`py-20 ${theme === 'light' ? 'bg-white' : 'bg-[#222]'}`}>
                <div className='container mx-auto px-4'>
                    <h2 className={`text-4xl font-bold text-center mb-14 ${theme === 'dark' ? 'text-white' : 'text-gray-900'}`}>
                        Šta nudimo
                    </h2>
                    <div className='grid grid-cols-1 md:grid-cols-2 gap-8'>
                        <div className={`${theme === 'light' ? 'bg-gray-50' : 'bg-[#444]'} p-8 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 flex flex-col justify-between`}>
                            <div>
                                <h3 className='text-xl font-semibold mb-4 opacity-75'>Booking ski instruktora</h3>
                                <p className='opacity-50'>Rezervišite profesionalne ski instruktore za individualne časove, grupe ili porodične ski ture. Nudimo iskusne instruktore koji prilagođavaju časove prema vašem nivou veštine.</p>
                            </div>
                            <button className='mt-4 w-full rounded-lg bg-blue-500 hover:bg-blue-600 text-white text-sm py-4'>Pogledaj više</button>
                        </div>
                        <div className={`${theme === 'light' ? 'bg-gray-50' : 'bg-[#444]'} p-8 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 flex flex-col justify-between`}>
                            <div>
                                <h3 className='text-xl font-semibold mb-4 opacity-75'>Iznajmljivanje ski opreme</h3>
                                <p className='opacity-50'>Kompletna ski oprema za početnike i profesionalce. Nudimo vrhunske brendove ski opreme uz mogućnost višednevnog iznajmljivanja.</p>
                            </div>
                            <button className='mt-4 w-full rounded-lg bg-green-500 hover:bg-green-600 text-white text-sm py-4'>Pogledaj više</button>
                        </div>
                        
                    </div>
                </div>
            </section>

            <section className='py-20'>
                <div className='container mx-auto px-4'>
                    <h2 className='text-4xl font-bold text-center mb-12'>Pozitivni komentari</h2>
                    <div className='grid grid-cols-1 md:grid-cols-3 gap-8'>
                        {getCurrentComments().map((comment, index) => (
                            <div key={index} className={`${theme === 'light' ? 'bg-gray-50' : 'bg-[#444]'} flex flex-col justify-between p-8 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 text-center`}>
                                <div>
                                    <h3 className='text-xl font-semibold mb-2 opacity-75'>{comment.name}</h3>
                                    <p className='opacity-50 italic'>"{comment.comment}"</p>
                                </div>
                                <p className='mt-4 text-yellow-500'>
                                    {Array(comment.rating).fill().map((_, i) => '★').join('')} {/* Ocjena zvjezdicama */}
                                </p>
                            </div>
                        ))}
                    </div>
                </div>
            </section>

        </div>
    );
}

export default Home;
