import React, { useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { ChevronDown, Moon, Sun } from 'lucide-react';
import { useTheme } from '../context/ThemeContext';
import logoLight from '../assets/logo/skiberg.png';  // Assuming this is the light theme logo
import logoDark from '../assets/logo/skiberg-light.png';  // Dark theme logo
import '../theme.css'
import { useAppData } from '../context/ApplicationContext';

function ClientLayout({ children }) {
    const [isOpen, setIsOpen] = useState(false);
    const toggleDropdown = () => setIsOpen(!isOpen);

    const { theme, toggleTheme } = useTheme();

    const location = useLocation();

    const isActive = (path) => location.pathname.includes(path);

    const { school, rental, instruktori, klijenti, handleLogOut } = useAppData();

    return (
        <div className={`flex flex-col h-screen ${theme}`}>
            <header className="application-header flex items-center flex-wrap justify-between" style={{ height: 60 }}>
                {/* Change logo based on the theme */}
                <img src={theme === 'dark' ? logoDark : logoLight} alt="Logo" />

                <nav className="h-full flex flex-wrap">

                    <button className='px-5' onClick={toggleTheme}>
                        {theme === "light" ? <Moon /> : <Sun />}
                    </button>

                    <Link
                        to="/client/dashboard"
                        className={`text-sm header-btn h-full flex items-center justify-center px-4 ${isActive('/client/dashboard') ? 'bg-green-600 text-white' : ''}`}
                    >
                        Kontrolna tabla
                    </Link>

                    <div className="relative" style={{ width: 200 }}>
                        <div
                            onClick={toggleDropdown}
                            className={`text-sm header-btn h-full flex items-center justify-between relative px-4 gap-2 cursor-pointer ${isActive('/client/instructors') ? 'bg-blue-600 text-white' : ''}`}
                        >
                            Instruktori <ChevronDown />
                        </div>
                        {isOpen && (
                            <div className="absolute dropdown-menu top-full left-0 w-full mt-1 shadow-lg z-9999">
                                <Link
                                    to="/client/instructors/all-instructors"
                                    className={`block header-btn p-4 border-b text-sm ${isActive(`/client/instructors/all-instructors`) ? 'bg-blue-600 text-white' : ''}`}
                                >
                                    Svi instruktori
                                </Link>
                                {instruktori && instruktori.map((instructor) => (
                                    <Link
                                        key={instructor.id_instruktora}
                                        to={`/client/instructors/instructor/${instructor.id_instruktora}`}
                                        className={`block header-btn p-4 border-b text-sm ${isActive(`/client/instructors/instructor/${instructor.id_instruktora}`) ? 'bg-blue-600 text-white' : ''}`}
                                    >
                                        {instructor.ime_prezime}
                                    </Link>
                                ))}
                            </div>
                        )}
                    </div>

                    <Link
                        to="/client/reservations"
                        className={`text-sm header-btn h-full flex items-center justify-center px-4 ${isActive('/client/reservations') ? 'bg-red-600 text-white' : ''}`}
                    >
                        Rezervacije
                    </Link>

                    {
                        school.id && <Link to="/client/dashboard" className={`${isActive('/client') ? 'bg-green-500 text-white' : ''} text-sm header-btn h-full flex items-center justify-center px-4 `}>MOJA SKI SKOLA</Link>
                    }

                    {
                        rental.id && <Link to="/rental" className={`${isActive('/rental') ? 'bg-green-500 text-white' : ''} text-sm header-btn h-full flex items-center justify-center px-4 `}>MOJ SKI RENTAL</Link>
                    }
                    
                    <Link
                        onClick={handleLogOut}
                        to="/"
                        className={`text-sm header-btn h-full flex items-center justify-center px-4 bg-red-100 text-red-600`}
                    >
                        Odjavi se
                    </Link>
                </nav>
            </header>

            <main className={`flex-1 application-page overflow-hidden`}>
                {children}
            </main>
        </div>
    );
}

export default ClientLayout;
