import React, { useState } from 'react';
import { useTheme } from '../../../context/ThemeContext';

function ContactSupport() {
    const { theme } = useTheme();
    const [formData, setFormData] = useState({ name: '', email: '', message: '' });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Logika za slanje poruke (npr. slanje putem API-a)
        console.log('Poruka poslana:', formData);
    };

    return (
        <div className={`min-h-screen py-20 px-6 ${theme === 'dark' ? 'bg-[#1a1a1a] text-white' : 'bg-gray-100 text-gray-800'}`}>
            <div className='container mx-auto'>
                <h1 className='text-4xl font-bold mb-8'>Kontakt podrška</h1>
                <form onSubmit={handleSubmit} className='space-y-6'>
                    <div>
                        <label htmlFor="name" className='block text-sm font-medium'>Vaše ime</label>
                        <input
                            type="text"
                            id="name"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            className={`w-full p-2 mt-2 border ${theme === 'dark' ? 'bg-gray-800 text-white' : 'bg-white text-gray-800'}`}
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="email" className='block text-sm font-medium'>Vaš email</label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            className={`w-full p-2 mt-2 border ${theme === 'dark' ? 'bg-gray-800 text-white' : 'bg-white text-gray-800'}`}
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="message" className='block text-sm font-medium'>Poruka</label>
                        <textarea
                            id="message"
                            name="message"
                            rows="5"
                            value={formData.message}
                            onChange={handleChange}
                            className={`w-full p-2 mt-2 border ${theme === 'dark' ? 'bg-gray-800 text-white' : 'bg-white text-gray-800'}`}
                            required
                        ></textarea>
                    </div>
                    <button
                        type="submit"
                        className='px-6 py-2 bg-blue-600 text-white font-bold rounded-md hover:bg-blue-700 transition duration-300'
                    >
                        Pošalji poruku
                    </button>
                </form>
            </div>
        </div>
    );
}

export default ContactSupport;
