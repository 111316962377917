// src/components/ProtectedRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';
import Cookies from 'js-cookie';

const ProtectedRoute = ({ children }) => {
    const userId = Cookies.get('userId');
    const token = Cookies.get('token');

    if (!userId || !token) {
        return <Navigate to="/authentication" />;
    }

    return children; // Render the children if user is authenticated
};

export default ProtectedRoute;
