import React, { useState } from 'react'
import { Check, Edit2, Trash2, UserRound, X } from 'lucide-react'
import { useTheme } from '../../context/ThemeContext'
import { useAppData } from '../../context/ApplicationContext';
import dayjs from 'dayjs';

function Instructors() {

    const [ formData, setFormData ] = useState({
        ime: "",
        biografija: "",
        iskusto: 0,
        cijenaPoSatu: 0,
        email: "",
        brojMobitel: "",
        maxBrojUcenik: 0,
        certifikati: [],
        nivoiSkijanja: [],
        usluge: [],
        promoPonude: null
    })

    const { theme } = useTheme();

    const [ createInstructor, setCreateInstructor ] = useState(false);

    const handleCreateInstructor = () => {
        setCreateInstructor(!createInstructor);
    };

    const { instruktori, reservations, pricePerClass } = useAppData();

    // const totalPriceForInstructor = reservations
    //     ?.filter((rez) => rez.instruktor_id === user[0]?.id_instruktora) // Filter by instructor ID
    //     ?.reduce((total, rez) => {
    //         const calcHours = Number(rez?.vrijeme_zavrsetka?.replace(":00", "")) - Number(rez?.vrijeme_pocetka?.replace(":00", ""));

    //         // Find the price for the reservation type
    //         const foundPrice = pricePerClass?.find((ppc) => ppc.title.toLowerCase() === rez?.reservation_type?.toLowerCase())?.price || 0;

    //         return total + (foundPrice * calcHours);
    //     }, 0); // Initialize total as 0

    // // Format the total price
    // const formattedTotalPrice = totalPriceForInstructor?.toLocaleString(undefined, { maximumFractionDigits: 2 });

    const calcTimeDifference = (startTime, endTime) => {
        if (!startTime || !endTime) {
            console.error("Invalid time input", { startTime, endTime });
            return 0; // Default value if input is invalid
        }

        // Split the time strings and convert to numbers
        const [startHours, startMinutes, startSeconds] = startTime.split(":").map(Number);
        const [endHours, endMinutes, endSeconds] = endTime.split(":").map(Number);

        // Convert everything to total minutes for easier calculation
        const startTotalMinutes = startHours * 60 + startMinutes + startSeconds / 60;
        const endTotalMinutes = endHours * 60 + endMinutes + endSeconds / 60;

        // Calculate the total difference in minutes
        const totalMinutesDifference = endTotalMinutes - startTotalMinutes;

        // Convert back to hours and fractions of hours
        const hours = Math.floor(totalMinutesDifference / 60);
        const minutes = totalMinutesDifference % 60;

        return hours + minutes / 60; // Return the difference in hours with fractions
    };


    return (
        <div className='p-3 flex items-start justify-between'>
            <div className='flex-1'>
                <div className='mb-3 flex items-center justify-end gap-3'>
                    <button onClick={handleCreateInstructor} className='text-xs'>Kreiraj instruktora</button>
                </div>
                <table className={`w-full border ${theme === 'light' ? null : "border-gray-500"}`}>
                    <thead className='w-full'>
                        <tr className={`border-b ${theme === 'light' ? null : "border-gray-500"}`}>
                            <th style={{ width: '10%' }} className={`p-2 font-normal text-left border-r ${theme === 'light' ? null : "border-gray-500"} text-sm`}>Ime i Prezime</th>
                            <th style={{ width: '10%' }} className={`p-2 font-normal text-left border-r ${theme === 'light' ? null : "border-gray-500"} text-sm`}>Broj Mobitela</th>
                            <th style={{ width: '10%' }} className={`p-2 font-normal text-left border-r ${theme === 'light' ? null : "border-gray-500"} text-sm`}>Ukupan broj časova</th>
                            <th style={{ width: '10%' }} className={`p-2 font-normal text-left border-r ${theme === 'light' ? null : "border-gray-500"} text-sm`}>Broj otkazanih časova</th>
                            <th style={{ width: '10%' }} className={`p-2 font-normal text-left border-r ${theme === 'light' ? null : "border-gray-500"} text-sm`}>Ukupna zarada</th>
                            <th style={{ width: '10%' }} className={`p-2 font-normal text-left border-r ${theme === 'light' ? null : "border-gray-500"} text-sm`}>Instruktorova zarada</th>
                            <th style={{ width: '10%' }} className={`p-2 font-normal text-left border-r ${theme === 'light' ? null : "border-gray-500"} text-sm`}>Akcije</th>
                            <th style={{ width: '10%' }} className='p-2 font-normal text-center text-xs w-1/12'>Datum registrovanja</th>
                        </tr>
                    </thead>
                    <tbody>

                        {
                            instruktori ? instruktori.map((ins) => {
                                
                                return (
                                    <tr className={`border-b ${theme === 'light' ? null : "border-gray-500"}`}>
                                        <td className={`p-2 text-left text-sm border-r ${theme === 'light' ? null : "border-gray-500"}`}>{ins?.ime_prezime}</td>
                                        <td className={`p-2 text-left text-sm border-r ${theme === 'light' ? null : "border-gray-500"}`}>{ins?.broj_mobitel}</td>
                                        <td className={`p-2 text-left text-sm border-r ${theme === 'light' ? null : "border-gray-500"}`}>{reservations?.filter((res) => res.instruktor_id === ins?.id_instruktora).length}</td>
                                        <td className={`p-2 text-left text-sm border-r ${theme === 'light' ? null : "border-gray-500"}`}>{reservations?.filter((res) => res.instruktor_id === ins?.id_instruktora &&  res.status_rezervacije === "Otkazana").length}</td>
                                        <td className={`p-2 text-left text-sm border-r ${theme === 'light' ? null : "border-gray-500"}`}>
                                            {
                                                reservations?.filter((rez) => rez.instruktor_id === ins?.id_instruktora && rez.status_rezervacije === "Završena")?.reduce((total, rez) => {
                                                        // Calculate the duration of the reservation in hours
                                                    const calcHours = calcTimeDifference(rez?.vrijeme_pocetka, rez?.vrijeme_zavrsetka);

                                                        // Find the price for the reservation type
                                                        const pricePerClassForRez = pricePerClass
                                                            .find((ppc) => ppc.title.toLowerCase() === rez?.reservation_type.toLowerCase())?.price || 0;

                                                        // Return the accumulated total, adding the price * hours for the current reservation
                                                    return total + (pricePerClassForRez * calcHours)
                                                    }, 0)
                                            } EUR</td>
                                        <td className={`p-2 text-left text-sm border-r ${theme === 'light' ? null : "border-gray-500"}`}>
                                            {
                                                reservations?.filter((rez) => rez.instruktor_id === ins?.id_instruktora && rez.status_rezervacije === "Završena")?.reduce((total, rez) => {
                                                    // Calculate the duration of the reservation in hours
                                                    const calcHours = calcTimeDifference(rez?.vrijeme_pocetka, rez?.vrijeme_zavrsetka);

                                                    // Find the price for the reservation type
                                                    const pricePerClassForRez = pricePerClass
                                                        .find((ppc) => ppc.title.toLowerCase() === rez?.reservation_type.toLowerCase())?.price || 0;

                                                    // Return the accumulated total, adding the price * hours for the current reservation
                                                    return total + (pricePerClassForRez * calcHours)
                                                }, 0) * 0.35
                                            } EUR</td>
                                        <td className={`p-2 text-center border-r ${theme === 'light' ? null : "border-gray-500"} flex items-center justify-center`}>
                                            <button className='w-1/2 p-2 flex items-center justify-center rounded-tl-lg rounded-bl-lg text-white bg-red-500'><Trash2 size={14} /></button>
                                            <button className='w-1/2 p-2 flex items-center justify-center rounded-tr-lg rounded-br-lg text-white bg-blue-500'><Edit2 size={14} /></button>
                                        </td>
                                        <td className='p-2 text-center text-xs'>{dayjs(ins?.created_at).format("DD.MM.YYYY")}</td>
                                    </tr>
                                )
                            }) : null
                        }

                    
                    </tbody>
                </table>
            </div>
            {
                createInstructor ? (
                    <div className={`create-instructor ml-3 w-1/5 p-3 ${theme === 'light' ? 'bg-gray-100' : 'bg-[#444]'}`}>
                        <h3 className='text-sm pb-3 border-b'>Kreiraj instruktora</h3>
                        <div className="create-instructor-form my-3 flex flex-col gap-3">
                            <input className={`w-full text-xs p-3 outline-none ${theme === 'light' ? 'bg-white text-black' : 'bg-[#333] text-white'}`} type="text" placeholder="Unesite ime i prezime" />
                            <textarea className={`w-full text-xs p-3 outline-none ${theme === 'light' ? 'bg-white text-black' : 'bg-[#333] text-white'}`} type="text" placeholder="Unesite biografiju" />
                            <input className={`w-full text-xs p-3 outline-none ${theme === 'light' ? 'bg-white text-black' : 'bg-[#333] text-white'}`} type="text" placeholder="Unesite iskustvo" />
                            <input className={`w-full text-xs p-3 outline-none ${theme === 'light' ? 'bg-white text-black' : 'bg-[#333] text-white'}`} type="text" placeholder="Unesite cijenu po satu" />
                            <p style={{ fontSize: 10 }}>Kontakt informacije</p>
                            <input className={`w-full text-xs p-3 outline-none ${theme === 'light' ? 'bg-white text-black' : 'bg-[#333] text-white'}`} type="email" placeholder="Unesite email" />
                            <input className={`w-full text-xs p-3 outline-none ${theme === 'light' ? 'bg-white text-black' : 'bg-[#333] text-white'}`} type="tel" placeholder="Unesite mobitel" />
                        </div>
                        <p style={{ fontSize: 10 }} className='uppercase'>Opcionalno</p>
                        <div className='create-instructor-form my-3 flex flex-col gap-3'>
                            <input className={`w-full text-xs p-3 outline-none ${theme === 'light' ? 'bg-white text-black' : 'bg-[#333] text-white'}`} type="number" placeholder="Unesite maksimalan broj učenika po času" />
                            <input className={`w-full text-xs p-3 outline-none ${theme === 'light' ? 'bg-white text-black' : 'bg-[#333] text-white'}`} type="text" placeholder="Unesite certifikate i kvalifikacije" />
                            <input className={`w-full text-xs p-3 outline-none ${theme === 'light' ? 'bg-white text-black' : 'bg-[#333] text-white'}`} type="text" placeholder="Unesite nivoe skijanja" />
                            <input className={`w-full text-xs p-3 outline-none ${theme === 'light' ? 'bg-white text-black' : 'bg-[#333] text-white'}`} type="text" placeholder="Unesite usluge" />
                            <button onClick={() => setFormData(prev => ({...prev, promoPonude: !formData.promoPonude}))} className={`w-full flex items-center gap-3 text-xs p-3 outline-none ${theme === 'light' ? 'bg-white text-black' : 'bg-[#333] text-white'}`}>
                                <div
                                    className={`rounded-full flex items-center justify-center ${formData.promoPonude
                                            ? 'bg-blue-500 text-white'
                                            : theme === 'light'
                                                ? 'bg-gray-100'
                                                : 'bg-[#444]'
                                        }`}
                                    style={{ width: 22, height: 22 }}
                                >
                                    {formData.promoPonude ? <Check size={14} /> : null}
                                </div>
                                <p>Promo ponude</p>
                            </button>
                        </div>
                        <button className='text-xs bg-blue-500 text-white w-full text-center'>Kreiraj instruktora</button>
                    </div>
                ) : null
            }
            
        </div>
    )
}

export default Instructors