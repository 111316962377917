import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';

function Authentication() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    const handleLogin = async () => {

        setLoading(true);
        setError('');

        try {
            const response = await fetch('https://skiberg.arenabackend.org/api/korisnik/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email, password }),
            });

            if (!response.ok) {
                throw new Error('Login failed');
            }

            const data = await response.json();
            Cookies.set('token', data.token, { expires: 7 });
            Cookies.set('userId', data.userId, { expires: 7 });

            navigate('/client/dashboard');
            
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
        
    };

    return (
        <div className='flex items-center justify-center w-screen h-screen'>
            <div className='auth-form w-2/6 h-auto p-10 rounded-lg bg-gray-50'>
                <img src={require('../assets/logo/skiberg.png')} alt="" />
                <input
                    type="email"
                    className='rounded-lg focus:border-b border-b-blue-500'
                    placeholder="Unesite mail adresu"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <input
                    type="password"
                    className='rounded-lg focus:border-b border-b-blue-500'
                    placeholder="Unesite lozinku"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                {error && <p className="text-red-500">{error}</p>}
                <button
                    onClick={handleLogin}
                    className='bg-blue-600 hover:bg-blue-500 rounded-lg text-white flex items-center justify-center'
                    disabled={loading}
                >
                    {loading ? 'Prijavljivanje...' : 'Prijavi se'}
                </button>
            </div>
        </div>
    );
}

export default Authentication;
