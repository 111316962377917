import React, { useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { addDays, endOfWeek, format } from 'date-fns';
import dayjs from 'dayjs';
import { useTheme } from '../context/ThemeContext';
import { useAppData } from '../context/ApplicationContext';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

const hours = Array.from({ length: 7 }, (_, i) => `${i + 9} - ${i + 10}`);

const InstructorSchedule = ({ instructors, reservations, pricePerClass }) => {

    const {
        setStartTime,
        setEndTime,
        setReservationDate,
        setInstructorId
    } = useAppData();

    const [selectedDates, setSelectedDates] = useState([new Date()]);
    const { theme } = useTheme();

    const formatDate = (date) => format(date, 'dd.MM');

    const handleQuickPick = (type) => {
        const today = new Date();
        let dates = [];

        if (type === 'today') {
            dates = [today];
        } else if (type === 'thisWeek') {
            const start = today;
            const end = endOfWeek(today);
            for (let d = start; d <= end; d = addDays(d, 1)) {
                dates.push(d);
            }
        }

        setSelectedDates(dates);
    };

   
    const isSlotBusy = (date, hourRange, instructor) => {
        // Adjust to split on '-' instead of ' - '
        const [startHour, endHour] = hourRange.split('-').map(Number); // Convert to numbers directly

        // Create start and end timestamps for the requested slot
        const slotStart = dayjs(date).hour(startHour).minute(0).second(0).millisecond(0).valueOf(); // Using valueOf() for timestamp
        const slotEnd = dayjs(date).hour(endHour).minute(0).second(0).millisecond(0).valueOf();

        // Filter reservations for the selected date and instructor
        const dayReservations = reservations.filter((reservation) => {
            return (
                dayjs(reservation.datum_rezervacije).isSame(date, 'day') &&
                reservation.instruktor_id === instructor
            );
        });

        return dayReservations.some((reservation) => {
            // Combine date and time into a valid format
            const resStartString = `${dayjs(reservation.datum_rezervacije).format('YYYY-MM-DD')} ${reservation.vrijeme_pocetka}`;
            const resEndString = `${dayjs(reservation.datum_rezervacije).format('YYYY-MM-DD')} ${reservation.vrijeme_zavrsetka}`;

            // Create timestamps for reservation start and end
            const resStart = dayjs(resStartString).valueOf(); // Use valueOf() to get timestamp
            const resEnd = dayjs(resEndString).valueOf(); // Use valueOf() to get timestamp

            // Check for NaN values
            if (isNaN(resStart) || isNaN(resEnd)) {
                console.error(`Invalid reservation times: ${resStartString} or ${resEndString}`);
            }

            // Overlap condition
            return slotStart < resEnd && slotEnd > resStart; // Check for overlapping slots
        });
    };

    const getPriceForReservationType = (type) => {
        const classInfo = pricePerClass.find(price => price.title === type);
        return classInfo ? classInfo.price : 0; // Return 0 if not found
    };

    // Function to calculate total earnings for an instructor
    const calculateEarnings = (instructorId) => {
        let totalEarnings = 0;

        const instructorReservations = reservations.filter(reservation => reservation.instruktor_id === instructorId);

        instructorReservations.forEach(reservation => {
            const price = getPriceForReservationType(reservation.reservation_type);

            if (price > 0) {
                const startTime = parseFloat(reservation.vrijeme_pocetka);
                const endTime = parseFloat(reservation.vrijeme_zavrsetka);
                const durationInHours = endTime - startTime;

                console.log(startTime)

                totalEarnings += price * durationInHours; // Calculate total earnings for this reservation
            }
        });

        return totalEarnings;
    };

    return (
        <div className="">

            <div className="flex justify-between items-center mb-3">
                <h2 className="font-medium uppercase">Raspored instruktora</h2>
                <div className='flex gap-3'>
                    <button
                        onClick={() => handleQuickPick('today')}
                        className={`px-4 py-2 text-sm ${selectedDates.length === 1 ? 'bg-blue-500 text-white' : null}`}
                    >
                        Danas
                    </button>
                    <button
                        onClick={() => handleQuickPick('thisWeek')}
                        className={`px-4 py-2 text-sm ${selectedDates.length > 1 ? 'bg-blue-500 text-white' : null}`}
                    >
                        Ova sedmica
                    </button>
                </div>
            </div>

            {selectedDates.length > 0 && (
                <div className="overflow-x-auto">
                    <table className="table-auto border-collapse border text-center w-full">
                        <thead>
                            <tr>
                                <th className="p-2 border text-left" style={{ minWidth: 120 }}></th>
                                <th className="p-2 border text-left" style={{ minWidth: 80 }}></th>
                                {selectedDates.map((date, index) => (
                                    <th key={index} colSpan={hours.length} className="p-2 text-xs border">
                                        {formatDate(date)}
                                    </th>
                                ))}
                            </tr>
                            <tr>
                                <th className="text-xs p-2 border text-left" style={{ minWidth: 120 }}>Instruktor</th>
                                <th className="text-xs p-2 border text-left" style={{ minWidth: 80 }}>ZARADA</th>
                                {selectedDates.map(() =>
                                    hours.map((hour, index) => (
                                        <th key={index} className="px-4 text-xs py-2 border">
                                            {hour}
                                        </th>
                                    ))
                                )}
                            </tr>
                        </thead>
                        <tbody>
                            {instructors.map((instruktor) => (
                                <tr key={instruktor.id}>
                                    <td className="p-2 border text-left text-xs">
                                        {instruktor.ime_prezime}
                                    </td>
                                    <td className="p-2 border text-left text-xs">
                                        {calculateEarnings(instruktor.id_instruktora).toFixed(2)} EUR
                                    </td>
                                    {selectedDates.map((date) =>
                                        hours.map((hour, index) => (
                                            <td
                                                key={index}
                                                className={`p-1 border text-xs ${theme === 'light' ? 'hover:bg-gray-100' : 'hover:bg-[#444]'} cursor-pointer`}
                                            >
                                                {isSlotBusy(dayjs(date), hour, Number(instruktor.id_instruktora)) ? (
                                                    <button
                                                        className="p-2 rounded-full bg-red-100 text-red-800 min-w-full"
                                                    >
                                                        Zauzeto
                                                    </button>
                                                ) : (
                                                    <button
                                                        onClick={() => {
                                                            const [startHour, endHour] = hour.split(" - ");
                                                            const formattedStart = `${startHour.padStart(2, '0')}:00`;
                                                            const formattedEnd = `${endHour.padStart(2, '0')}:00`;
                                                            const formattedDate = dayjs(date).format("YYYY-MM-DD");

                                                            setReservationDate(formattedDate);
                                                            setStartTime(formattedStart);
                                                            setEndTime(formattedEnd);
                                                            setInstructorId(instruktor.id_instruktora);
                                                        }}
                                                        className="p-2 rounded-full bg-green-100 text-green-800 min-w-full"
                                                    >
                                                        Slobodno
                                                    </button>
                                                )}
                                            </td>
                                        ))
                                    )}

                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );
};

export default InstructorSchedule;
