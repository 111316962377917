// src/store/actions/appActions.js
import axios from 'axios';
import Cookies from 'js-cookie';


export const fetchSchool = () => {
    return async (dispatch) => {
        const userId = Cookies.get('userId');

        try {
            const response = await axios.get(`https://skiberg.arenabackend.org/api/skola/${Number(userId)}`);
            dispatch({ type: 'SET_SCHOOL', payload: response.data });
        } catch (error) {
            dispatch({ type: 'SET_SCHOOL', payload: [] });
        }
    };
};


export const fetchRental = () => {
    return async (dispatch) => {
        const userId = Cookies.get('userId');

        try {
            const response = await axios.get(`https://skiberg.arenabackend.org/api/rental/${userId}`);
            dispatch({ type: 'SET_RENTAL', payload: response.data });
        } catch (error) {
            dispatch({ type: 'SET_RENTAL', payload: [] });
        }
    };
};


export const fetchReservations = (schoolId) => {
    return async (dispatch) => {
        const token = Cookies.get('token');

        try {
            const response = await axios.get(`https://skiberg.arenabackend.org/api/rezervacije/${schoolId}`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            dispatch({ type: 'SET_RESERVATIONS', payload: response.data });
        } catch (error) {
            dispatch({ type: 'SET_RESERVATIONS', payload: [] });
        }
    };
};

export const fetchPricesPerClass = (schoolId) => {
    return async (dispatch) => {
        const token = Cookies.get('token');

        try {
            const response = await axios.get(`https://skiberg.arenabackend.org/api/rezervacije/cijene-po-casu/1`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            dispatch({ type: 'SET_PRICES_PER_CLASS', payload: response.data });
        } catch (error) {
            console.error('Error fetching reservations:', error);
            dispatch({ type: 'SET_ERROR', payload: 'Error fetching reservations' });
        }
    };
};

export const fetchEquipment = (rentalId) => {
    return async (dispatch) => {
        const token = Cookies.get('token');

        try {
            const response = await axios.get(`https://skiberg.arenabackend.org/api/oprema/${rentalId}`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            dispatch({ type: 'SET_EQUIPMENT', payload: response.data });
        } catch (error) {
            console.error('Error fetching equipment:', error);
            dispatch({ type: 'SET_ERROR', payload: 'Error fetching equipment' });
        }
    };
};

export const fetchInstructors = (schoolId) => {
    return async (dispatch) => {
        const token = Cookies.get('token');

        try {
            const response = await axios.get(`https://skiberg.arenabackend.org/api/instruktori/${schoolId}`)
            dispatch({ type: 'SET_INSTRUCTORS', payload: response.data });
        } catch (error) {
            console.error('Error fetching instructors:', error);
            dispatch({ type: 'SET_ERROR', payload: 'Error fetching instructors' });
        }
    };
};

export const fetchKlijenti = () => {
    return async (dispatch) => {
        const token = Cookies.get('token');

        try {
            const response = await axios.get(`https://skiberg.arenabackend.org/api/klijent`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            dispatch({ type: 'SET_KLIJENTI', payload: response.data });
        } catch (error) {
            dispatch({ type: 'SET_KLIJENTI', payload: [] });
        }
    };
};

export const fetchRentedEquipment = (rentalId) => {
    return async (dispatch) => {
        const token = Cookies.get('token');

        try {
            const response = await axios.get(`https://skiberg.arenabackend.org/api/rented_equipment/${rentalId}`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            dispatch({ type: 'SET_RENTED_EQUIPMENT', payload: response.data });
        } catch (error) {
            dispatch({ type: 'SET_RENTED_EQUIPMENT', payload: [] });
        }
    };
};

export const fetchRentedEquipmentItems = () => {
    return async (dispatch) => {
        const token = Cookies.get('token');

        try {
            const response = await axios.get(`https://skiberg.arenabackend.org/api/rented_equipment_items`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            dispatch({ type: 'SET_RENTED_EQUIPMENT_ITEMS', payload: response.data });
        } catch (error) {
            dispatch({ type: 'SET_RENTED_EQUIPMENT_ITEMS', payload: [] });
        }
    };
};


export const createReservation = (reservationData) => {
    return async (dispatch) => {
        const token = Cookies.get('token');

        try {
            dispatch({ type: 'SET_LOADING', payload: true });

            const response = await axios.post('https://skiberg.arenabackend.org/api/rezervacije', reservationData, {
                headers: { Authorization: `Bearer ${token}` },
            });
            
            dispatch({ type: 'ADD_RESERVATION', payload: response.data });

            dispatch({ type: 'SET_LOADING', payload: false });

        } catch (error) {
            // Handle error
            console.error('Error creating reservation:', error);
            dispatch({ type: 'SET_ERROR', payload: 'Error creating reservation' });
            dispatch({ type: 'SET_LOADING', payload: false });
        }
    };
};

export const createEquipment = (equipmentData) => {
    return async (dispatch) => {
        const token = Cookies.get('token');

        try {
            dispatch({ type: 'SET_LOADING', payload: true });

            const response = await axios.post('https://skiberg.arenabackend.org/api/oprema', equipmentData, {
                headers: { Authorization: `Bearer ${token}` },
            });

            dispatch({ type: 'ADD_EQUIPMENT', payload: response.data });

            dispatch({ type: 'SET_LOADING', payload: false });

        } catch (error) {
            // Handle error
            console.error('Error creating equipment:', error);
            dispatch({ type: 'SET_ERROR', payload: 'Error creating equipment' });
            dispatch({ type: 'SET_LOADING', payload: false });
        }
    };
};

export const createRentedEquipment = (equipmentData) => {
    return async (dispatch) => {
        const token = Cookies.get('token');

        try {
            dispatch({ type: 'SET_LOADING', payload: true });

            const response = await axios.post('https://skiberg.arenabackend.org/api/rented_equipment', equipmentData, {
                headers: { Authorization: `Bearer ${token}` },
            });

            dispatch({ type: 'ADD_RENT_EQUIPMENT', payload: response.data });

            dispatch({ type: 'SET_LOADING', payload: false });

        } catch (error) {
            // Handle error
            console.error('Error creating equipment:', error);
            dispatch({ type: 'SET_ERROR', payload: 'Error creating equipment' });
            dispatch({ type: 'SET_LOADING', payload: false });
        }
    };
};


export const fetchSchools = () => {
    return async (dispatch) => {
        try {
            const response = await axios.get(`https://skiberg.arenabackend.org/api/skola`);
            dispatch({ type: 'SET_SCHOOLS', payload: response.data });
        } catch (error) {
            dispatch({ type: 'SET_SCHOOLS', payload: [] });
        }
    };
};


export const fetchRentals = () => {
    return async (dispatch) => {
        try {
            const response = await axios.get(`https://skiberg.arenabackend.org/api/rental`);
            dispatch({ type: 'SET_RENTALS', payload: response.data });
        } catch (error) {
            dispatch({ type: 'SET_RENTALS', payload: [] });
        }
    };
};

export const fetchOprema = () => {
    return async (dispatch) => {
        try {
            const response = await axios.get(`https://skiberg.arenabackend.org/api/oprema`);
            dispatch({ type: 'SET_OPREMA', payload: response.data });
        } catch (error) {
            dispatch({ type: 'SET_OPREMA', payload: [] });
        }
    };
};


export const fetchAllInstructors = () => {
    return async (dispatch) => {
        try {
            const response = await axios.get(`https://skiberg.arenabackend.org/api/instruktori`);
            dispatch({ type: 'SET_ALL_INSTRUCTORS', payload: response.data });
        } catch (error) {
            dispatch({ type: 'SET_ALL_INSTRUCTORS', payload: [] });
        }
    };
};

export const fetchAllReservations = () => {
    return async (dispatch) => {
        try {
            const response = await axios.get(`https://skiberg.arenabackend.org/api/rezervacije`);
            dispatch({ type: 'SET_ALL_RESERVATIONS', payload: response.data });
        } catch (error) {
            dispatch({ type: 'SET_ALL_RESERVATIONS', payload: [] });
        }
    };
};