import React from 'react';
import { useTheme } from '../../context/ThemeContext';

// Oprema (fake data)
const oprema = [
    {
        id: 1,
        equipmentNumber: 15212,
        name: "Fischer Skije",
        brand: "Fischer",
        type: "Skije",
        length_size: "185",
        condition: "Novo",
        price_per_day: 15,
        weight: 1005,
        year_of_manufacture: "2024",
        level_of_skier: "Srednji",
        age_group: "Dijeca",
        quantity: 5,
        reservedQuantity: 2,
        description: "Savršene skije za srednje skijaše."
    },
    {
        id: 2,
        equipmentNumber: 18172,
        name: "Fischer Pancerice",
        brand: "Fischer",
        type: "Pancerice",
        length_size: "35",
        condition: "Korišteno",
        price_per_day: 7.5,
        weight: 250,
        year_of_manufacture: "2022",
        level_of_skier: "Pocetnik, Srednji, Iskusan",
        age_group: "Odrasli",
        quantity: 15,
        reservedQuantity: 2,
        description: "Udobne pancerice za sve nivoe skijanja."
    },
    {
        id: 3,
        equipmentNumber: 19321,
        name: "Atomic Snowboard",
        brand: "Atomic",
        type: "Snowboard",
        length_size: "150",
        condition: "Novo",
        price_per_day: 20,
        weight: 1800,
        year_of_manufacture: "2024",
        level_of_skier: "Napredni",
        age_group: "Odrasli",
        quantity: 10,
        reservedQuantity: 2,
        description: "Idealno za napredne vozače."
    },
    {
        id: 4,
        equipmentNumber: 19452,
        name: "Leki Štapovi",
        brand: "Leki",
        type: "Štapovi",
        length_size: "120",
        condition: "Novo",
        price_per_day: 5,
        weight: 300,
        year_of_manufacture: "2023",
        level_of_skier: "Svi nivoi",
        age_group: "Dijeca, Odrasli",
        quantity: 2,
        reservedQuantity: 2,
        description: "Lagani i izdržljivi štapovi."
    },
    {
        id: 5,
        equipmentNumber: 20563,
        name: "Nordica Pancerice",
        brand: "Nordica",
        type: "Pancerice",
        length_size: "40",
        condition: "Korišteno",
        price_per_day: 10,
        weight: 1200,
        year_of_manufacture: "2021",
        level_of_skier: "Srednji",
        age_group: "Odrasli",
        quantity: 7,
        reservedQuantity: 2,
        description: "Pancerice sa odličnom podrškom."
    },
    {
        id: 6,
        equipmentNumber: 21684,
        name: "Burton Snowboard",
        brand: "Burton",
        type: "Snowboard",
        length_size: "155",
        condition: "Novo",
        price_per_day: 22,
        weight: 2000,
        year_of_manufacture: "2024",
        level_of_skier: "Svi nivoi",
        age_group: "Dijeca, Odrasli",
        quantity: 12,
        reservedQuantity: 2,
        description: "Prilagođeno za sve nivoe skijaša."
    },
    {
        id: 7,
        equipmentNumber: 22705,
        name: "Salomon Skije",
        brand: "Salomon",
        type: "Skije",
        length_size: "170",
        condition: "Novo",
        price_per_day: 18,
        weight: 900,
        year_of_manufacture: "2023",
        level_of_skier: "Iskusan",
        age_group: "Odrasli",
        quantity: 6,
        reservedQuantity: 2,
        description: "Savršene skije za iskusne skijaše."
    },
    {
        id: 8,
        equipmentNumber: 23826,
        name: "Atomic Pancerice",
        brand: "Atomic",
        type: "Pancerice",
        length_size: "42",
        condition: "Korišteno",
        price_per_day: 8,
        weight: 1150,
        year_of_manufacture: "2020",
        level_of_skier: "Srednji",
        age_group: "Odrasli",
        quantity: 5,
        reservedQuantity: 2,
        description: "Udobne i praktične pancerice."
    },
];


const generateReservedDates = (reservedQuantity, daysInMonth) => {
    const reservedDates = new Set();
    while (reservedDates.size < reservedQuantity) {
        const day = Math.floor(Math.random() * daysInMonth) + 1;
        reservedDates.add(day);
    }
    return Array.from(reservedDates);
};

const EquipmentCalendar = () => {
    const daysInWeek = ['Pon', 'Uto', 'Sri', 'Čet', 'Pet', 'Sub', 'Ned'];
    const weeks = 5;
    const daysInMonth = 31;

    const { theme } = useTheme();

    return (
        <div className="w-full p-4">
            <table className={`min-w-full border-collapse border ${theme === 'light' ? null : 'border-[#444]'}`}>
                <thead>
                    <tr>
                        <th className={`border ${theme === 'light' ? null : 'border-[#444]'}  p-2 font-normal text-sm uppercase text-left`}>Oprema</th>
                        {Array.from({ length: weeks }, (_, i) => (
                            <th key={i} className={`border ${theme === 'light' ? null : 'border-[#444]'}  p-2 font-normal text-sm uppercase text-left`}>
                                Sedmica {i + 1}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {oprema.map((item) => {
                        const reservedDates = generateReservedDates(item.reservedQuantity, daysInMonth);
                        return (
                            <tr key={item.id} className={`${theme === 'light' ? 'hover:bg-gray-100' : 'hover:bg-[#444]'} `}>
                                <td className={`border ${theme === 'light' ? null : 'border-[#444]'} p-2 text-sm`}>
                                    {item.name} | <strong className='text-xs'>{reservedDates.length * item.price_per_day} EUR</strong>
                                </td>
                                {Array.from({ length: weeks }, (_, weekIndex) => (
                                    <td key={weekIndex} className={`border ${theme === 'light' ? null : 'border-[#444]'} p-2 text-xs text-center`}>
                                        <div className="grid grid-cols-7 gap-1">
                                            {daysInWeek.map((day, dayIndex) => {
                                                const dayNumber = weekIndex * 7 + (dayIndex + 1);
                                                return (
                                                    <div key={dayIndex} className={`border rounded-full ${theme === 'light' ? null : 'border-[#444]'}  p-1 ${reservedDates.includes(dayNumber) ? 'bg-red-100 text-red-600' : ''}`}>
                                                        {dayNumber <= daysInMonth ? day : <span className='opacity-25'>/</span>}
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </td>
                                ))}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};

export default EquipmentCalendar;