import React from 'react'
import { Check, Edit, Edit2, Mail, Phone, Trash, Trash2, UserRound } from 'lucide-react'
import { useTheme } from '../../context/ThemeContext'
import { Link, useParams } from 'react-router-dom';
import { useAppData } from '../../context/ApplicationContext';
import dayjs from 'dayjs';

function Instructor() {

  const { theme } = useTheme();

  const { instructorId } = useParams();
  
  const { instruktori, reservations, pricePerClass, klijenti } = useAppData();

  const user = instruktori.filter((instruktor) => instruktor.id_instruktora === Number(instructorId))

  const calcTimeDifference = (startTime, endTime) => {
    if (!startTime || !endTime) {
      console.error("Invalid time input", { startTime, endTime });
      return 0; // Default value if input is invalid
    }

    // Split the time strings and convert to numbers
    const [startHours, startMinutes, startSeconds] = startTime.split(":").map(Number);
    const [endHours, endMinutes, endSeconds] = endTime.split(":").map(Number);

    // Convert everything to total minutes for easier calculation
    const startTotalMinutes = startHours * 60 + startMinutes + startSeconds / 60;
    const endTotalMinutes = endHours * 60 + endMinutes + endSeconds / 60;

    // Calculate the total difference in minutes
    const totalMinutesDifference = endTotalMinutes - startTotalMinutes;

    // Convert back to hours and fractions of hours
    const hours = Math.floor(totalMinutesDifference / 60);
    const minutes = totalMinutesDifference % 60;

    return hours + minutes / 60; // Return the difference in hours with fractions
  };


  return (
    <div className='p-3 flex items-start'>

      <div className={`p-6 w-full max-w-sm shadow-lg rounded-lg mr-3 ${theme === 'light' ? 'bg-gray-100' : 'bg-[#444]'}`}>
        <h3 className={`text-xl font-semibold ${theme === 'light' ? 'text-gray-800' : 'text-white'}`}>{user[0].ime_prezime}</h3>

        <div className="mt-4">
          <h4 className={`text-sm font-medium ${theme === 'light' ? 'text-gray-600' : 'text-gray-400'}`}>Biografija</h4>
          <p className={`text-sm ${theme === 'light' ? 'text-gray-700' : 'text-gray-300'} mt-1`}>{user[0].biografija}</p>
        </div>

        <div className="mt-4">
          <h4 className={`text-sm font-medium ${theme === 'light' ? 'text-gray-600' : 'text-gray-400'}`}>Iskustvo</h4>
          <p className={`text-sm ${theme === 'light' ? 'text-gray-700' : 'text-gray-300'} mt-1`}>{user[0].iskustvo_godine} god.</p>
        </div>

        <div className="mt-4">
          <h4 className={`text-sm font-medium ${theme === 'light' ? 'text-gray-600' : 'text-gray-400'}`}>Email</h4>
          <p className={`text-sm flex items-center ${theme === 'light' ? 'text-gray-700' : 'text-gray-300'} mt-1`}>
            <Mail className="mr-2" size={16} />
            {user[0].email}
          </p>
        </div>

        <div className="mt-4">
          <h4 className={`text-sm font-medium ${theme === 'light' ? 'text-gray-600' : 'text-gray-400'}`}>Broj mobitela</h4>
          <p className={`text-sm flex items-center ${theme === 'light' ? 'text-gray-700' : 'text-gray-300'} mt-1`}>
            <Phone className="mr-2" size={16} />
            {user[0].broj_mobitel}
          </p>
        </div>

        <div className="mt-4">
          <h4 className={`text-sm font-medium ${theme === 'light' ? 'text-gray-600' : 'text-gray-400'}`}>Maks. broj učenika po času</h4>
          <p className={`text-sm ${theme === 'light' ? 'text-gray-700' : 'text-gray-300'} mt-1`}>{user[0].max_broj_ucenika}</p>
        </div>


        <div className={`mt-6 p-3 rounded-lg text-center ${user[0].promo_ponude ? 'bg-green-500' : 'bg-gray-400'} text-white`}>
          {user[0].promo_ponude ? (
            <div className="flex items-center justify-center gap-3">
              <Check className="text-white" size={20} />
              Promo ponude aktivne
            </div>
          ) : (
            "Nema promo ponude"
          )}
        </div>
          <button className="w-full mt-3 flex items-center justify-center gap-3 bg-red-500 rounded-lg text-center text-white p-3">
            <Trash2 className="text-white" size={20} />
            Ukloni
          </button>
      </div>


      <table className={`w-full border ${theme === 'light' ? null : "border-gray-500"}`}>
        <thead className='w-full'>
          <tr className={`border-b ${theme === 'light' ? null : "border-gray-500"}`}>
            <th style={{ width: '10%' }} className={`p-2 font-normal text-left border-r text-sm ${theme === 'light' ? null : "border-gray-500"}`}>Datum</th>
            <th style={{ width: '5%' }} className={`p-2 font-normal text-left border-r text-sm ${theme === 'light' ? null : "border-gray-500"}`}>Od</th>
            <th style={{ width: '5%' }} className={`p-2 font-normal text-left border-r text-sm ${theme === 'light' ? null : "border-gray-500"}`}>Do</th>
            <th style={{ width: '15%' }} className={`p-2 font-normal text-left border-r text-sm ${theme === 'light' ? null : "border-gray-500"}`}>Učenik</th>
            <th style={{ width: '15%' }} className={`p-2 font-normal text-left border-r text-sm ${theme === 'light' ? null : "border-gray-500"}`}>Broj mobitela</th>
            <th style={{ width: '10%' }} className={`p-2 font-normal text-left border-r text-sm ${theme === 'light' ? null : "border-gray-500"}`}>Nivo skijanja</th>
            <th style={{ width: '10%' }} className={`p-2 font-normal text-left border-r text-sm ${theme === 'light' ? null : "border-gray-500"}`}>Oprema</th>
            <th style={{ width: '10%' }} className={`p-2 font-normal text-left border-r text-sm ${theme === 'light' ? null : "border-gray-500"}`}>Vrsta rezervacije</th>
            <th style={{ width: '10%' }} className={`p-2 font-normal text-left border-r text-sm ${theme === 'light' ? null : "border-gray-500"}`}>Iznos</th>
            <th style={{ width: '10%' }} className={`p-2 font-normal text-left border-r text-sm ${theme === 'light' ? null : "border-gray-500"}`}>Status</th>
          </tr>
        </thead>

        <tbody>
          {
            reservations?.filter((res) => res.instruktor_id === user[0]?.id_instruktora).map((rez) => {
              
              const calcHours = calcTimeDifference(rez?.vrijeme_pocetka, rez?.vrijeme_zavrsetka);

              return (
                <tr className={`border-b ${theme === 'light' ? null : "border-gray-500"}`}>
                  <td className={`p-2 text-left border-r ${theme === 'light' ? null : "border-gray-500"} text-sm capitalize`}>{dayjs(rez.datum_rezervacije).format("DD.MM.YYYY")}</td>
                  <td className={`p-2 text-left border-r ${theme === 'light' ? null : "border-gray-500"} text-sm capitalize`}>{rez?.vrijeme_pocetka.replace(":00", "")}</td>
                  <td className={`p-2 text-left border-r ${theme === 'light' ? null : "border-gray-500"} text-sm capitalize`}>{rez?.vrijeme_zavrsetka.replace(":00","")}</td>
                  <td className={`text-left ${theme === "light" ? "hover:bg-gray-200" : "hover:bg-blue-500"} border-r ${theme === 'light' ? null : "border-gray-500"} text-sm`}>
                    <Link className='flex items-center gap-2 w-full h-full p-2 capitalize'>
                      {rez?.ime_prezime
                        ? rez.ime_prezime
                        : klijenti?.find((klijent) => klijent.id === rez?.korisnik_id)?.ime_prezime || 'Unknown User'}
                    </Link>
                  </td>
                  <td className={`p-2 text-left border-r ${theme === 'light' ? null : "border-gray-500"} text-sm capitalize`}>
                    {rez?.broj_mobitela
                      ? rez.broj_mobitela
                      : klijenti?.find((klijent) => klijent.id === rez?.korisnik_id)?.phone || 'Unknown User'}
                  </td>
                  <td className={`p-2 text-left border-r ${theme === 'light' ? null : "border-gray-500"} text-sm capitalize`}>
                    {rez?.nivo_skijanja
                      ? rez.nivo_skijanja
                      : klijenti?.find((klijent) => klijent.id === rez?.korisnik_id)?.nivo_skijanja || 'Unknown User'}
                  </td>
                  <td className={`p-2 text-left border-r ${theme === 'light' ? null : "border-gray-500"} text-sm capitalize`}>
                    {rez?.oprema
                      ? rez.oprema
                      : klijenti?.find((klijent) => klijent.id === rez?.korisnik_id)?.oprema || 'Unknown User'}
                  </td>
                  <td className={`p-2 text-left border-r ${theme === 'light' ? null : "border-gray-500"} text-sm`}>{rez?.reservation_type}</td>
                  <td className={`p-2 text-left border-r ${theme === 'light' ? null : "border-gray-500"} text-sm`}>
                    {
                      (pricePerClass.filter((ppc) => ppc.title.toLowerCase() === rez?.reservation_type.toLowerCase())
                        .map((ppc) => ppc.price) * calcHours).toLocaleString(void 0, { maximumFractionDigits: 2 })
                    } 
                    <span className='ml-3'>EUR</span>
                    </td>
                  <td className='p-2 text-center text-sm bg-green-100 text-green-600'>Završeno</td>
                </tr>
              )
            })
          }
        </tbody>
      </table>

    </div>
  )
}

export default Instructor