// src/store/store.js
import { createStore, applyMiddleware } from 'redux';
import rootReducer from './reducers';
import { thunk } from 'redux-thunk';
import authMiddleware from './middleware/authMiddleware'; // Import your middleware

const store = createStore(
    rootReducer,
    applyMiddleware(thunk, authMiddleware) // Add your middleware here
);

export default store;
