import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { ThemeProvider } from '../../context/ThemeContext'
import Home from './Home'
import MainLayout from '../../layout/MainLayout'
import TermsOfUse from './privacy-pages/TermsOfUse'
import PrivacyPolicy from './privacy-pages/PrivacyPolicy'
import TermsConditions from './privacy-pages/TermsConditions'
import ContactSupport from './privacy-pages/ContactSupport'
import Rezervisi from './Rezervisi'

function MainRouter() {
    return (
        <ThemeProvider>
            <MainLayout>
                <Routes>
                    <Route path='/' element={<Home />} />
                    <Route path='/rezervisi' element={<Rezervisi />} />
                    <Route path='/pravila-koriscenja' element={<TermsOfUse />} />
                    <Route path='/politika-privatnosti' element={<PrivacyPolicy />} />
                    <Route path='/uvijeti-i-odredbe' element={<TermsConditions />} />
                    <Route path='/kontakt-podrska' element={<ContactSupport />} />
                </Routes>
            </MainLayout>
        </ThemeProvider>
    )
}

export default MainRouter