import React, { useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { ChevronDown, Moon, Sun } from 'lucide-react';
import { useTheme } from '../context/ThemeContext';
import logoLight from '../assets/logo/skiberg.png';  // Assuming this is the light theme logo
import logoDark from '../assets/logo/skiberg-light.png';  // Dark theme logo
import '../theme.css'
import { useAppData } from '../context/ApplicationContext';

function RentLayout({ children }) {

    const { theme, toggleTheme } = useTheme();  // useTheme() should work if ThemeProvider is set correctly

    const location = useLocation();
    const navigate = useNavigate();

    const isActive = (path) => location.pathname.includes(path);

    const { school, rental, handleLogOut } = useAppData();


    return (
        <div className={`flex flex-col h-screen ${theme}`}>
            <header className="application-header flex items-center justify-between" style={{ height: 60 }}>
                {/* Change logo based on the theme */}
                <img src={theme === 'dark' ? logoDark : logoLight} alt="Logo" />

                <nav className="h-full flex">
                    <button className='px-5' onClick={toggleTheme}>
                        {theme === "light" ? <Moon /> : <Sun />}
                    </button>

                    <Link
                        to="/rental/"
                        className={`text-sm header-btn h-full flex items-center justify-center px-4 ${isActive('/rental/') ? 'bg-green-600 text-white' : ''}`}
                    >
                        Kontrolna tabla
                    </Link>


                    <Link
                        to="/rental/oprema"
                        className={`text-sm header-btn h-full flex items-center justify-center px-4 ${isActive('/rental/oprema') ? 'bg-red-600 text-white' : ''}`}
                    >
                        Oprema
                    </Link>

                    <Link
                        to="/rental/iznajmljena-oprema"
                        className={`text-sm header-btn h-full flex items-center justify-center px-4 ${isActive('/rental/iznajmljena-oprema') ? 'bg-yellow-500 text-white' : ''}`}
                    >
                        Iznajmljena oprema
                    </Link>

                    <Link
                        to="/rental/kalendar-iznajmljene-oprema"
                        className={`text-sm header-btn h-full flex items-center justify-center px-4 ${isActive('/rental/kalendar-iznajmljene-oprema') ? 'bg-orange-600 text-white' : ''}`}
                    >
                        Kalendar iznajmljivanja
                    </Link>

                    {
                        school.id && <Link to="/client/dashboard" className={`${isActive('/client/dashboard') ? 'bg-green-500 text-white' : ''} text-sm header-btn h-full flex items-center justify-center px-4 `}>MOJA SKI SKOLA</Link>
                    }

                    {
                        rental.id && <Link to="/rental" className={`${isActive('/rental') ? 'bg-green-500 text-white' : ''} text-sm header-btn h-full flex items-center justify-center px-4 `}>MOJ SKI RENTAL</Link>
                    }

                    <Link
                        onClick={handleLogOut}
                        to="/"
                        className={`text-sm header-btn h-full flex items-center justify-center px-4 bg-red-100 text-red-600`}
                    >
                        Odjavi se
                    </Link>
                </nav>
            </header>

            <main className={`flex-1 application-page`}>
                {children}
            </main>
        </div>
    );
}

export default RentLayout;
