import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Dashboard from './Dashboard'
import ClientLayout from '../../layout/ClientLayout'
import Instructors from './Instructors'
import { ThemeProvider } from '../../context/ThemeContext'
import Instructor from './Instructor'
import Reservations from './Reservations'

function CRouter() {
    return (
        <ThemeProvider>
            <ClientLayout>
                <Routes>
                    <Route path='/dashboard' element={<Dashboard />} />
                    <Route path='/instructors/all-instructors' element={<Instructors />} />
                    <Route path='/instructors/instructor/:instructorId' element={<Instructor />} />
                    <Route path='/reservations' element={<Reservations />} />
                </Routes>
            </ClientLayout>
        </ThemeProvider>
    )
}

export default CRouter