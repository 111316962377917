import React from 'react';
import { useTheme } from '../../../context/ThemeContext';

function TermsOfUse() {
    const { theme } = useTheme();

    return (
        <div className={`min-h-screen py-20 px-6 ${theme === 'dark' ? 'bg-[#1a1a1a] text-white' : 'bg-gray-100 text-gray-800'}`}>
            <div className='container mx-auto'>
                <h1 className='text-4xl font-bold mb-8'>Pravila korištenja</h1>
                <p className='mb-4'>
                    Dobrodošli na našu web stranicu. Ovi uvjeti korištenja uređuju vaš pristup i korištenje naše usluge. Pristupanjem stranici prihvatate sljedeća pravila i uslove.
                </p>
                <p className='mb-4'>
                    ... (Detaljna pravila korištenja ovdje) ...
                </p>
            </div>
        </div>
    );
}

export default TermsOfUse;
