import React from 'react';
import logoLight from '../assets/logo/skiberg.png';
import logoDark from '../assets/logo/skiberg-light.png';
import { useTheme } from '../context/ThemeContext';
import { Link } from 'react-router-dom';

function Footer() {
    const { theme } = useTheme();

    return (
        <footer className={`py-10 ${theme === 'dark' ? 'bg-[#444]' : 'bg-gray-200'} text-${theme === 'dark' ? 'white' : 'gray-800'}`}>
            <div className='container mx-auto px-4'>
                <div className='grid grid-cols-1 md:grid-cols-3 gap-8'>
                    <div className='flex flex-col items-center md:items-start'>
                        <img
                            src={theme === 'dark' ? logoDark : logoLight}
                            alt="Logo"
                            className='mb-4 w-40'
                        />
                        <p className={`text-${theme === 'dark' ? 'gray-400' : 'gray-600'} text-center md:text-left w-3/4`}>
                            Skiberg nudi vrhunske ski instruktore i opremu na <strong>OC Jahorina</strong>.
                        </p>
                    </div>

                    {/* Navigacija i linkovi */}
                    <div className='flex flex-col items-center md:items-start'>
                        <h3 className='font-semibold mb-4'>Navigacija</h3>
                        <ul className='space-y-2'>
                            <li><Link to="/" className='hover:text-blue-500 transition-colors duration-300'>Početna</Link></li>
                            <li><Link to="/" className='hover:text-blue-500 transition-colors duration-300'>Šta nudimo</Link></li>
                            <li><Link to="/" className='hover:text-blue-500 transition-colors duration-300'>Kontakt</Link></li>
                            <li><Link to="/" className='hover:text-blue-500 transition-colors duration-300'>O nama</Link></li>
                        </ul>
                    </div>

                    {/* Pravila i kontakt */}
                    <div className='flex flex-col items-center md:items-start'>
                        <h3 className='font-semibold mb-4'>Pravna sekcija</h3>
                        <ul className='space-y-2'>
                            <li><Link to='/pravila-koriscenja' className='hover:text-blue-500 transition-colors duration-300'>Pravila korištenja</Link></li>
                            <li><Link to='/politika-privatnosti' className='hover:text-blue-500 transition-colors duration-300'>Politika privatnosti</Link></li>
                            <li><Link to='/uvijeti-i-odredbe' className='hover:text-blue-500 transition-colors duration-300'>Uvjeti i odredbe</Link></li>
                            <li><Link to='/kontakt-podrska' className='hover:text-blue-500 transition-colors duration-300'>Kontakt podrška</Link></li>
                        </ul>
                    </div>
                </div>

                {/* Bottom row */}
                <div className='mt-8 border-t pt-6 text-center'>
                    <p className={`text-sm ${theme === 'dark' ? 'text-gray-400' : 'text-gray-600'}`}>
                        © 2024 Skiberg. Sva prava zadržana.
                    </p>
                    <p className={`text-sm ${theme === 'dark' ? 'text-gray-400' : 'text-gray-600'} mt-2`}>
                        Stranicu izradio: <span className='font-semibold'>David Obradović</span>
                    </p>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
