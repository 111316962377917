import React, { useState } from 'react'
import { useAppData } from '../../context/ApplicationContext'
import { UserRound } from 'lucide-react';
import InstructorSchedule from '../../components/InstructorSchedule'
import { useTheme } from '../../context/ThemeContext';
import { toast } from 'react-toastify';

function Rezervisi() {

    const { theme } = useTheme();

    const [reservationType, setReservationType] = useState(0)

    const [selectedRental, setSelectedRental] = useState(0)
    const [selectedSchool, setSelectedSchool] = useState(0)

    const [selectedInstructor, setSelectedInstructor] = useState(0)
    const [selectedEquipment, setSelectedEquipment] = useState([])

    const { rentals, schools, oprema, allInstructors, allReservations, pricePerClass, handleCreateReservation } = useAppData();

    const [newReservation, setNewReservation] = useState({
        school_id: selectedSchool,
        korisnik_id: null,
        ime_prezime: '',
        broj_mobitela: '',
        nivo_skijanja: '',
        datum_rezervacije: '',
        vrijeme_pocetka: '',
        vrijeme_zavrsetka: '',
        instruktor_id: 1,
        oprema: '',
        reservation_type: ''
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewReservation(prev => ({ ...prev, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            toast.success('Uspiješno kreirana rezervacija!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light"
            });
            await handleCreateReservation(newReservation);
            // setTimeout(() => {
            //     window.location.reload();
            // }, 5000)
        } catch (error) {
            console.error('Error creating reservation:', error);
        }
    }

    return (
        <div className={`w-full sm:w-3/5 mx-auto h-full p-4 gap-4 items-start`}>
            <div className="search-reservation p-3 bg-white rounded-lg border mb-3">

                <button onClick={() => setReservationType(0)} className={`text-xs ${reservationType === 0 ? 'text-white bg-blue-500' : 'text-black bg-gray-200'} rounded w-full`}>Instruktori</button>

                {
                    reservationType === 0 ? (
                        <>
                            <select className="border p-2 rounded w-full text-xs bg-gray-100 outline-none mt-3" value={selectedSchool} onChange={(e) => setSelectedSchool(e.target.value)}>
                                <option value={0}>Izaberi školu</option>
                                {schools.map((school, index) => (
                                    <option key={index} value={school.id}>{school.naziv}</option>
                                ))}
                            </select>

                        </>
                    ) : null
                }

            </div>
            <div className="flex-1">
                {
                    reservationType === 0 && selectedSchool ? (
                        <div className='bg-gray-50 p-3'>
                            <InstructorSchedule instructors={allInstructors.filter((ins) => Number(ins.school_id) === Number(selectedSchool))} reservations={allReservations} />
                            <div>
                                <div className='space-y-3 mt-3'>
                                    <input
                                        className={`p-3 text-sm rounded ${theme === 'light' ? 'bg-gray-100' : 'bg-[#333]'} w-full`}
                                        type="text"
                                        name="ime_prezime"
                                        placeholder="Ime i Prezime"
                                        value={newReservation.ime_prezime}
                                        onChange={handleInputChange}
                                    />
                                    <input
                                        className={`p-3 text-sm rounded ${theme === 'light' ? 'bg-gray-100' : 'bg-[#333]'} w-full`}
                                        type="text"
                                        name="broj_mobitela"
                                        placeholder="Broj mobitela"
                                        value={newReservation.broj_mobitela}
                                        onChange={handleInputChange}
                                    />
                                    <input
                                        className={`p-3 text-sm rounded ${theme === 'light' ? 'bg-gray-100' : 'bg-[#333]'} w-full`}
                                        type="text"
                                        name="nivo_skijanja"
                                        placeholder="Nivo skijanja"
                                        value={newReservation.nivo_skijanja}
                                        onChange={handleInputChange}
                                    />
                                    <select onChange={(e) => setNewReservation(prev => ({ ...prev, oprema: e.target.value }))} className={`p-3 text-sm rounded ${theme === 'light' ? 'bg-gray-100' : 'bg-[#333]'} w-full`}>
                                        <option>Izaberite opremu</option>
                                        <option value="osobna oprema">Osobna oprema</option>
                                        <option value="naša oprema">Naša oprema</option>
                                        <option value="iznajmljujem">Iznajmljena oprema</option>
                                    </select>
                                </div>
                                <select onChange={(e) => setNewReservation(prev => ({ ...prev, reservation_type: e.target.value }))} className={`p-3 mt-3 text-sm rounded ${theme === 'light' ? 'bg-gray-100' : 'bg-[#333]'} w-full`}>
                                    <option>Izaberite tip časa</option>
                                    {
                                        pricePerClass.map((pc) => {
                                            return (
                                                <option value={pc.title}>{pc.title} ({pc.price}E cijena po satu) </option>
                                            )
                                        })
                                    }
                                </select>
                                <label className="block text-sm font-medium opacity-75 mb-2 mt-3">Datum rezervacije</label>
                                <input
                                    type="date"
                                    name="datum_rezervacije"
                                    value={newReservation.datum_rezervacije}
                                    onChange={handleInputChange}
                                    className={`p-3 text-sm rounded ${theme === 'light' ? 'bg-gray-100' : 'bg-[#333]'} w-full mb-4`}
                                />

                                <label className="block text-sm font-medium opacity-75 mb-2">Vrijeme početka</label>
                                <input
                                    type="time"
                                    name="vrijeme_pocetka"
                                    value={newReservation.vrijeme_pocetka}
                                    onChange={handleInputChange}
                                    className={`p-3 text-sm rounded ${theme === 'light' ? 'bg-gray-100' : 'bg-[#333]'} w-full mb-4`}
                                />

                                <label className="block text-sm font-medium opacity-75 mb-2">Vrijeme završetka</label>
                                <input
                                    type="time"
                                    name="vrijeme_zavrsetka"
                                    value={newReservation.vrijeme_zavrsetka}
                                    onChange={handleInputChange}
                                    className={`p-3 text-sm rounded ${theme === 'light' ? 'bg-gray-100' : 'bg-[#333]'} w-full mb-4`}
                                />
                                <div className='grid grid-cols-1 sm:grid-cols-2 gap-3'>
                                    <button onClick={handleSubmit} className='p-3 text-xs rounded bg-blue-500 text-white'>
                                        Završi rezervaciju
                                    </button>
                                </div>
                            </div>
                        </div>
                    ) : null
                }
            </div>
        </div>
    )
}

export default Rezervisi