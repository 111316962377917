import React, { useEffect, useState } from 'react'
import { Phone, Shield, SnowflakeIcon, User, UserRound } from 'lucide-react'
import { Link } from 'react-router-dom'
import { useTheme } from '../../context/ThemeContext'
import InstructorSchedule from '../../components/InstructorSchedule';
import { useAppData } from '../../context/ApplicationContext';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';

function Reservations() {

    const { theme } = useTheme();
    const { instruktori, reservations, pricePerClass, klijenti, school, handleCreateReservation, instructorId,
        startTime,
        endTime,
        reservationDate } = useAppData();

    const [selectedType, setSelectedType] = useState(1)

    const [modalOpen, setModalOpen] = useState(null);
    

    const [newInstructor, setNewInstructor] = useState({
        school_id: '',
        korisnik_id: null,
        ime_prezime: '',
        broj_mobitela: '',
        nivo_skijanja: '',
        datum_rezervacije: '',
        vrijeme_pocetka: '',
        vrijeme_zavrsetka: '',
        instruktor_id: '',
        oprema: '',
        reservation_type: ''
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewInstructor(prev => ({ ...prev, [name]: value }));
    };

    const handleAddReservation = () => {
        console.log('Adding new equipment:', newInstructor);
        setModalOpen(false);
    };

    useEffect(() => {
        if (school.id) {
            setNewInstructor(prev => ({ ...prev, school_id: school.id }))
        }
    }, [school?.id])


    useEffect(() => {
        if (startTime && endTime && reservationDate && instructorId) {
            const date = dayjs(reservationDate).format('YYYY-MM-DD');

            setNewInstructor(prev => ({
                ...prev,
                datum_rezervacije: date,
                vrijeme_pocetka: startTime,
                vrijeme_zavrsetka: endTime,
                instruktor_id: Number(instructorId)
            }));

            setModalOpen(true)
        }
    }, [startTime, endTime, reservationDate, instructorId]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            toast.success('Uspiješno kreirana rezervacija!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light"
            });
            await handleCreateReservation(newInstructor);
            setTimeout(() => {
                window.location.reload();
            }, 5000)
        } catch (error) {
            console.error('Error creating reservation:', error);
        }
    }

    const calcTimeDifference = (startTime, endTime) => {
        if (!startTime || !endTime) {
            console.error("Invalid time input", { startTime, endTime });
            return 0; // Default value if input is invalid
        }

        // Split the time strings and convert to numbers
        const [startHours, startMinutes, startSeconds] = startTime.split(":").map(Number);
        const [endHours, endMinutes, endSeconds] = endTime.split(":").map(Number);

        // Convert everything to total minutes for easier calculation
        const startTotalMinutes = startHours * 60 + startMinutes + startSeconds / 60;
        const endTotalMinutes = endHours * 60 + endMinutes + endSeconds / 60;

        // Calculate the total difference in minutes
        const totalMinutesDifference = endTotalMinutes - startTotalMinutes;

        // Convert back to hours and fractions of hours
        const hours = Math.floor(totalMinutesDifference / 60);
        const minutes = totalMinutesDifference % 60;

        return hours + minutes / 60; // Return the difference in hours with fractions
    };


    return (
        <div className={`p-5 ${theme === 'light' ? 'bg-gray-50' : 'bg-[#444]'} h-full`}>
            <div className='grid grid-cols-1 sm:grid-cols-3 gap-3 mb-4'>
                <button onClick={() => setSelectedType(0)} className={`py-2 px-4 rounded ${selectedType === 0 ? 'bg-blue-500 text-white' : 'bg-gray-300 text-gray-800'}`}>TABELARNI PRIKAZ</button>
                <button onClick={() => setSelectedType(1)} className={`py-2 px-4 rounded ${selectedType === 1 ? 'bg-blue-500 text-white' : 'bg-gray-300 text-gray-800'}`}>KALENDARSKI PRIKAZ</button>
                <button onClick={() => setModalOpen(true)} className={`py-2 px-4 bg-green-500 text-white rounded hover:bg-green-600`}>KREIRAJ REZERVACIJU</button>
            </div>

            {selectedType === 0 ? (
                <div>
                    <div className='mb-6'>
                        <table className={`w-full border ${theme === 'light' ? 'border-gray-200' : 'border-[#333]'}`}>
                            <thead className={`${theme === 'light' ? 'bg-gray-200' : 'bg-[#333]'}`}>
                                <tr className='border-b'>
                                    <th className='p-3 text-left text-sm font-semibold'>Instruktor</th>
                                    <th className='p-3 text-left text-sm font-semibold'>Datum</th>
                                    <th className='p-3 text-left text-sm font-semibold'>Od</th>
                                    <th className='p-3 text-left text-sm font-semibold'>Do</th>
                                    <th className='p-3 text-left text-sm font-semibold'>Učenik</th>
                                    <th className='p-3 text-left text-sm font-semibold'>Broj mobitela</th>
                                    <th className='p-3 text-left text-sm font-semibold'>Nivo skijanja</th>
                                    <th className='p-3 text-left text-sm font-semibold'>Oprema</th>
                                    <th className='p-3 text-left text-sm font-semibold'>Ukupni iznos</th>
                                    <th className='p-3 text-left text-sm font-semibold'>Status rezervacije</th>
                                </tr>
                            </thead>
                            <tbody>
                                {reservations?.map((rez) => {
                                    const calcHours = calcTimeDifference(rez?.vrijeme_pocetka, rez?.vrijeme_zavrsetka);
                                    const instruktor = instruktori?.find((instr) => instr.id_instruktora === rez.instruktor_id);
                                    const klijent = klijenti?.find((klijent) => klijent.id === rez?.korisnik_id);
                                
                                    return (
                                        <tr className={`hover:bg-gray-100 ${theme === 'light' ? 'text-gray-800' : 'text-white'}`}>
                                            <td className='p-3 text-sm border-r'>{instruktor?.ime_prezime || 'Nepoznat instruktor'}</td>
                                            <td className='p-3 text-sm border-r'>{dayjs(rez.datum_rezervacije).format("DD.MM.YYYY")}</td>
                                            <td className='p-3 text-sm border-r'>{rez?.vrijeme_pocetka?.replace(":00", "")}</td>
                                            <td className='p-3 text-sm border-r'>{rez?.vrijeme_zavrsetka?.replace(":00", "")}</td>
                                            <td className='p-3 text-sm border-r'>{rez?.ime_prezime || klijent?.ime_prezime || 'Nepoznat učenik'}</td>
                                            <td className='p-3 text-sm border-r'>{rez?.broj_mobitela || klijent?.phone || 'Nepoznat broj'}</td>
                                            <td className='p-3 text-sm border-r'>{rez?.nivo_skijanja || klijent?.nivo_skijanja || 'Nepoznat nivo'}</td>
                                            <td className='p-3 text-sm border-r'>{rez?.oprema || klijent?.oprema || 'Nepoznata oprema'}</td>
                                            <td className='p-3 text-sm border-r'>{(pricePerClass.filter((ppc) => ppc?.title?.toLowerCase() === rez?.reservation_type?.toLowerCase())
                                                .map((ppc) => ppc.price) * calcHours).toLocaleString(void 0, { maximumFractionDigits: 2 })} EUR</td>
                                            <td className={` p-3 text-sm border-r ${rez?.status_rezervacije === "Aktivna" ? "bg-blue-100 text-blue-600 " : rez?.status_rezervacije === "Otkazana" ? "bg-red-100 text-red-600" : "bg-green-100 text-green-600"} `}>{rez?.status_rezervacije}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            ) : (
                    <InstructorSchedule instructors={instruktori ? instruktori : []} reservations={reservations ? reservations : []} pricePerClass={pricePerClass ? pricePerClass : []} />
            )}

            {modalOpen && (
                <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
                    <div className={`${theme === 'light' ? 'bg-white' : 'bg-[#444]'} p-6 rounded-lg shadow-lg w-full mx-10 sm:max-w-screen-md`}>
                        <h2 className="text-xl font-bold mb-4">Dodaj novu rezervaciju</h2>
                        <div className="space-y-4">
                            <input
                                className={`p-3 text-sm rounded ${theme === 'light' ? 'bg-gray-100' : 'bg-[#333]'} w-full`}
                                type="text"
                                name="school_id"
                                placeholder="ID SKOLE"
                                value={newInstructor.school_id}
                                onChange={handleInputChange}
                                disabled
                            />
                            <select value={newInstructor.instruktor_id} onChange={(e) => setNewInstructor(prev => ({ ...prev, instruktor_id: Number(e.target.value) }))} className={`p-3 text-sm rounded ${theme === 'light' ? 'bg-gray-100' : 'bg-[#333]'} w-full`}>
                                <option>Izaberite instruktora</option>
                                {instruktori.map((ins) => {
                                    return (
                                        <option value={ins?.id_instruktora}>{ins?.ime_prezime}</option>
                                    )
                                })}
                            </select>

                            <div className='grid grid-cols-1 sm:grid-cols-2 gap-3'>
                                <button className={`py-2 px-4 text-xs rounded ${newInstructor.korisnik_id ? 'bg-blue-500 text-white' : 'bg-gray-300 text-black'}`}>Registrovan učenik</button>
                                <button className={`py-2 px-4 text-xs rounded ${!newInstructor.korisnik_id ? 'bg-blue-500 text-white' : 'bg-gray-300 text-black'}`}>Neregistrovan učenik</button>
                            </div>
                            <input
                                className={`p-3 text-sm rounded ${theme === 'light' ? 'bg-gray-100' : 'bg-[#333]'} w-full`}
                                type="number"
                                name="korisnik_id"
                                placeholder="ID KORISNIKA"
                                value={newInstructor.korisnik_id}
                                onChange={(e) => setNewInstructor(prev => ({ ...prev, korisnik_id: Number(e.target.value) }))}
                            />
                            {!newInstructor.korisnik_id && (
                                <>
                                    <input
                                        className={`p-3 text-sm rounded ${theme === 'light' ? 'bg-gray-100' : 'bg-[#333]'} w-full`}
                                        type="text"
                                        name="ime_prezime"
                                        placeholder="Ime i Prezime"
                                        value={newInstructor.ime_prezime}
                                        onChange={handleInputChange}
                                    />
                                    <input
                                        className={`p-3 text-sm rounded ${theme === 'light' ? 'bg-gray-100' : 'bg-[#333]'} w-full`}
                                        type="text"
                                        name="broj_mobitela"
                                        placeholder="Broj mobitela"
                                        value={newInstructor.broj_mobitela}
                                        onChange={handleInputChange}
                                    />
                                    <input
                                        className={`p-3 text-sm rounded ${theme === 'light' ? 'bg-gray-100' : 'bg-[#333]'} w-full`}
                                        type="text"
                                        name="nivo_skijanja"
                                        placeholder="Nivo skijanja"
                                        value={newInstructor.nivo_skijanja}
                                        onChange={handleInputChange}
                                    />
                                    <select onChange={(e) => setNewInstructor(prev => ({ ...prev, oprema: e.target.value }))} className={`p-3 text-sm rounded ${theme === 'light' ? 'bg-gray-100' : 'bg-[#333]'} w-full`}>
                                        <option>Izaberite opremu</option>
                                        <option value="osobna oprema">Osobna oprema</option>
                                        <option value="naša oprema">Naša oprema</option>
                                        <option value="iznajmljujem">Iznajmljena oprema</option>
                                    </select>
                                </>
                            )}
                            {newInstructor.korisnik_id && klijenti.filter((klijent) => klijent.id === Number(newInstructor.korisnik_id)).map((klijent) => {
                                return (
                                    <div className='border rounded p-3 flex flex-col gap-3'>
                                        <p className='text-xs pb-3 border-b capitalize flex items-center gap-2'><User size={14} /> {klijent?.ime_prezime}</p>
                                        <p className='text-xs pb-3 border-b capitalize flex items-center gap-2'><Phone size={14} /> {klijent?.phone}</p>
                                        <p className='text-xs pb-3 border-b capitalize flex items-center gap-2'><Shield size={14} /> {klijent?.nivo_skijanja}</p>
                                        <p className='text-xs flex items-center capitalize gap-2'><SnowflakeIcon size={14} /> {klijent?.oprema}</p>
                                    </div>
                                )
                            })}
                            <select onChange={(e) => setNewInstructor(prev => ({ ...prev, reservation_type: e.target.value }))} className={`p-3 text-sm rounded ${theme === 'light' ? 'bg-gray-100' : 'bg-[#333]'} w-full`}>
                                <option>Izaberite tip časa</option>
                                {
                                    pricePerClass.map((pc) => {
                                        return (
                                            <option value={pc.title}>{pc.title} ({pc.price}E cijena po satu) </option>
                                        )
                                    })
                                }
                            </select>
                            <label className="block text-sm font-medium opacity-75 mb-2">Datum rezervacije</label>
                            <input
                                type="date"
                                name="datum_rezervacije"
                                value={newInstructor.datum_rezervacije}
                                onChange={handleInputChange}
                                className={`p-3 text-sm rounded ${theme === 'light' ? 'bg-gray-100' : 'bg-[#333]'} w-full mb-4`}
                            />

                            <label className="block text-sm font-medium opacity-75 mb-2">Vrijeme početka</label>
                            <input
                                type="time"
                                name="vrijeme_pocetka"
                                value={newInstructor.vrijeme_pocetka}
                                onChange={handleInputChange}
                                className={`p-3 text-sm rounded ${theme === 'light' ? 'bg-gray-100' : 'bg-[#333]'} w-full mb-4`}
                            />

                            <label className="block text-sm font-medium opacity-75 mb-2">Vrijeme završetka</label>
                            <input
                                type="time"
                                name="vrijeme_zavrsetka"
                                value={newInstructor.vrijeme_zavrsetka}
                                onChange={handleInputChange}
                                className={`p-3 text-sm rounded ${theme === 'light' ? 'bg-gray-100' : 'bg-[#333]'} w-full mb-4`}
                            />
                            <div className='grid grid-cols-1 sm:grid-cols-2 gap-3'>
                                <button onClick={handleSubmit} className='p-3 text-xs rounded bg-blue-500 text-white'>
                                    Završi rezervaciju
                                </button>
                                <button onClick={() => setModalOpen(null)} className={`p-3 text-xs rounded ${theme === 'light' ? null : 'bg-[#333]'}`}>
                                    Otkaži
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Reservations;
