// src/store/reducers/appReducer.js
const initialState = {
    isLoading: false,
    isError: "",
    reservations: [],
    instructors: [],
    school: {},
    rental: [],
    equipment: [],
    pricePerClass: [],
    klijenti: [],
    rentedEquipment: [],
    rentedEquipmentItems: [],
    rentals: [],
    schools: [],
    oprema: [],
    allInstructors: [],
    allReservations: []
};


const appReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_LOADING':
            return { ...state, isLoading: action.payload };
        case 'SET_ERROR':
            return { ...state, isError: action.payload };
        case 'SET_RESERVATIONS':
            return { ...state, reservations: action.payload };
        case 'ADD_RESERVATION':
            return { ...state, reservations: [...state.reservations, action.payload] };
        case 'SET_PRICES_PER_CLASS':
            return { ...state, pricePerClass: action.payload };
        case 'SET_INSTRUCTORS':
            return { ...state, instruktori: action.payload };
        case 'SET_SCHOOL':
            return { ...state, school: action.payload };
        case 'SET_RENTAL':
            return { ...state, rental: action.payload };
        case 'SET_EQUIPMENT':
            return { ...state, equipment: action.payload };
        case 'ADD_EQUIPMENT':
            return { ...state, equipment: [...state.equipment, action.payload] };
        case 'ADD_RENT_EQUIPMENT':
            return { ...state, equipment: [...state.equipment, action.payload] };
        case 'SET_KLIJENTI':
            return { ...state, klijenti: action.payload };
        case 'SET_RENTED_EQUIPMENT':
            return { ...state, rentedEquipment: action.payload };
        case 'SET_RENTED_EQUIPMENT_ITEMS':
            return { ...state, rentedEquipmentItems: action.payload };
        case 'SET_RENTALS':
            return { ...state, rentals: action.payload };
        case 'SET_SCHOOLS':
            return { ...state, schools: action.payload };
        case 'SET_OPREMA':
            return { ...state, oprema: action.payload };
        case 'SET_ALL_INSTRUCTORS':
            return { ...state, allInstructors: action.payload };
        case 'SET_ALL_RESERVATIONS':
            return { ...state, allReservations: action.payload };
        default:
            return state;
    }
};

export default appReducer;
