import React, { useState } from 'react';
import { useTheme } from '../../context/ThemeContext';
import { da } from 'date-fns/locale';
import { Trash2, X } from 'lucide-react';
import { useAppData } from '../../context/ApplicationContext';

const oprema = [
    {
        id: 1,
        equipmentNumber: 15212,
        name: "Fischer Skije",
        brand: "Fischer",
        type: "Skije",
        length_size: "185",
        condition: "Novo",
        price_per_day: 15,
        weight: 1005,
        year_of_manufacture: "2024",
        level_of_skier: "Srednji",
        age_group: "Dijeca",
        quantity: 5,
        reservedQuantity: 2,
        description: "Savršene skije za srednje skijaše."
    },
    {
        id: 2,
        equipmentNumber: 18172,
        name: "Fischer Pancerice",
        brand: "Fischer",
        type: "Pancerice",
        length_size: "35",
        condition: "Korišteno",
        price_per_day: 7.5,
        weight: 250,
        year_of_manufacture: "2022",
        level_of_skier: "Pocetnik, Srednji, Iskusan",
        age_group: "Odrasli",
        quantity: 15,
        reservedQuantity: 2,
        description: "Udobne pancerice za sve nivoe skijanja."
    },
    {
        id: 3,
        equipmentNumber: 19321,
        name: "Atomic Snowboard",
        brand: "Atomic",
        type: "Snowboard",
        length_size: "150",
        condition: "Novo",
        price_per_day: 20,
        weight: 1800,
        year_of_manufacture: "2024",
        level_of_skier: "Napredni",
        age_group: "Odrasli",
        quantity: 10,
        reservedQuantity: 2,
        description: "Idealno za napredne vozače."
    },
    {
        id: 4,
        equipmentNumber: 19452,
        name: "Leki Štapovi",
        brand: "Leki",
        type: "Štapovi",
        length_size: "120",
        condition: "Novo",
        price_per_day: 5,
        weight: 300,
        year_of_manufacture: "2023",
        level_of_skier: "Svi nivoi",
        age_group: "Dijeca, Odrasli",
        quantity: 2,
        reservedQuantity: 2,
        description: "Lagani i izdržljivi štapovi."
    },
    {
        id: 5,
        equipmentNumber: 20563,
        name: "Nordica Pancerice",
        brand: "Nordica",
        type: "Pancerice",
        length_size: "40",
        condition: "Korišteno",
        price_per_day: 10,
        weight: 1200,
        year_of_manufacture: "2021",
        level_of_skier: "Srednji",
        age_group: "Odrasli",
        quantity: 7,
        reservedQuantity: 2,
        description: "Pancerice sa odličnom podrškom."
    },
    {
        id: 6,
        equipmentNumber: 21684,
        name: "Burton Snowboard",
        brand: "Burton",
        type: "Snowboard",
        length_size: "155",
        condition: "Novo",
        price_per_day: 22,
        weight: 2000,
        year_of_manufacture: "2024",
        level_of_skier: "Svi nivoi",
        age_group: "Dijeca, Odrasli",
        quantity: 12,
        reservedQuantity: 2,
        description: "Prilagođeno za sve nivoe skijaša."
    },
    {
        id: 7,
        equipmentNumber: 22705,
        name: "Salomon Skije",
        brand: "Salomon",
        type: "Skije",
        length_size: "170",
        condition: "Novo",
        price_per_day: 18,
        weight: 900,
        year_of_manufacture: "2023",
        level_of_skier: "Iskusan",
        age_group: "Odrasli",
        quantity: 6,
        reservedQuantity: 2,
        description: "Savršene skije za iskusne skijaše."
    },
    {
        id: 8,
        equipmentNumber: 23826,
        name: "Atomic Pancerice",
        brand: "Atomic",
        type: "Pancerice",
        length_size: "42",
        condition: "Korišteno",
        price_per_day: 8,
        weight: 1150,
        year_of_manufacture: "2020",
        level_of_skier: "Srednji",
        age_group: "Odrasli",
        quantity: 5,
        reservedQuantity: 2,
        description: "Udobne i praktične pancerice."
    },
];

const reservationsData = [
    {
        id: 1,
        equipmentId: 1,
        skierName: "Jovan Mirković",
        startDate: "2024-11-10",
        endDate: "2024-11-11",
        totalDays: 1
    },
    {
        id: 2,
        equipmentId: 1,
        skierName: "Bojana Mirković",
        startDate: "2024-12-10",
        endDate: "2024-12-13",
        totalDays: 3
    },
    {
        id: 3,
        equipmentId: 1,
        skierName: "Marko Marković",
        startDate: "2024-14-10",
        endDate: "2024-14-18",
        totalDays: 8
    }
]


function Dashboard() {

    const { theme } = useTheme();
    const { equipment } = useAppData();

    const [ selectedEquipment, setSelectedEquipment ] = useState(null)

    const [condition, setCondition] = useState("");
    const [available, setAvailable] = useState(null);
    const [brandSearch, setBrandSearch] = useState("");
    const [sizeSearch, setSizeSearch] = useState("");
    const [numberSearch, setNumberSearch] = useState(null);

    const filteredOprema = equipment.filter((equipment) => {
        const matchesCondition = condition ? equipment.equpiment_condition === condition : true;
        const matchesAvailability = available !== null ? equipment.quantity - equipment.reservedQuantity > 0 === available : true;
        const matchesBrand = equipment.brand.toLowerCase().includes(brandSearch.toLowerCase());
        const matchesSize = equipment.length_size.toLowerCase().includes(sizeSearch.toLowerCase());
        const matchesNumber = numberSearch ? String(equipment.equipmentNumber).toLowerCase().includes(String(numberSearch).toLowerCase()) : true

        return matchesCondition && matchesAvailability && matchesBrand && matchesSize && matchesNumber;
    });

    return (
        <div className="w-full p-4">
            <div className={`w-full`}>

                <div className='mb-4 flex items-center justify-between flex-wrap gap-3'>
                    <h2 className="text-xl font-bold opacity-75">MOJA OPREMA</h2>
                    <div className="equipment-filters grid grid-cols-2 md:grid-cols-6 w-full md:w-auto gap-3">
                        <button onClick={() => setCondition(condition === "Novo" ? "" : "Novo")} className={`filter-btn text-xs ${condition === "Novo" ? "bg-blue-500 text-white" : null}`}>Novo</button>
                        <button onClick={() => setCondition(condition === "Korišteno" ? "" : "Korišteno")} className={`filter-btn text-xs ${condition === "Korišteno" ? "bg-blue-500 text-white" : null}`}>Korišteno</button>
                        <button onClick={() => setAvailable(available ? null : true)} className={`filter-btn text-xs ${available ? "bg-blue-500 text-white" : null}`}>Dostupno</button>
                        <input
                            className='text-xs outline-none search-input w-auto'
                            type="text"
                            placeholder="Pretražite po brendu"
                            value={brandSearch}
                            onChange={(e) => setBrandSearch(e.target.value)}
                        />
                        <input
                            
                            className='text-xs outline-none search-input '
                            type="text"
                            placeholder="Pretražite po veličini"
                            value={sizeSearch}
                            onChange={(e) => setSizeSearch(e.target.value)}
                        />
                        <input

                            className='text-xs outline-none search-input '
                            type="number"
                            placeholder="Pretražite po broju"
                            value={numberSearch}
                            onChange={(e) => setNumberSearch(e.target.value)}
                        />
                    </div>
                </div>

                {/* Tabela opreme */}
                <div className="overflow-x-auto">
                    <table className="min-w-full border">
                        <thead className={`${theme === 'light' ? 'bg-gray-200' : 'bg-[#555]'}`}>
                            <tr className='border-b'>
                                <th className="p-2 text-xs text-left font-medium opacity-50">Broj</th>
                                <th className="p-2 text-xs text-left font-medium opacity-50">Naziv</th>
                                <th className="p-2 text-xs text-left font-medium opacity-50">Brend</th>
                                <th className="p-2 text-xs text-left font-medium opacity-50">Tip opreme</th>
                                <th className="p-2 text-xs text-left font-medium opacity-50">Dimenzije</th>
                                <th className="p-2 text-xs text-left font-medium opacity-50">Stanje opreme</th>
                                <th className="p-2 text-xs text-left font-medium opacity-50">Cijena (po danu)</th>
                                <th className="p-2 text-xs text-center font-medium opacity-50">Težina</th>
                                <th className="p-2 text-xs text-left font-medium opacity-50">Godina proizvodnje</th>
                                <th className="p-2 text-xs text-left font-medium opacity-50">Nivo skijaša</th>
                                <th className="p-2 text-xs text-left font-medium opacity-50">Starosna grupa</th>
                                <th className="p-2 text-xs text-left font-medium opacity-50">Količina</th>
                                <th className="p-2 text-xs text-left font-medium opacity-50">Preostalo</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredOprema.map((equipment) => (
                                <tr key={equipment.id} className={`border-b`}>
                                    <td onClick={() => setSelectedEquipment(equipment.id)} className="p-2 border-r text-xs cursor-pointer hover:bg-blue-500 hover:text-white">{equipment.equipmentNumber}</td>
                                    <td className="p-2 border-r text-xs">{equipment.name}</td>
                                    <td className="p-2 border-r text-xs">{equipment.brand}</td>
                                    <td className="p-2 border-r text-xs">{equipment.type}</td>
                                    <td className="p-2 border-r text-xs">{equipment.length_size}</td>
                                    <td className="p-2 border-r text-xs">{equipment.equpiment_condition}</td>
                                    <td className="p-2 border-r text-xs">{equipment.price_per_day} EUR</td>
                                    <td className="p-2 border-r text-center text-xs">{equipment.weight ? `${equipment.weight} g` : "-"}</td>
                                    <td className="p-2 border-r text-xs">{equipment.year_of_manufacture}</td>
                                    <td className="p-2 border-r text-xs capitalize">{equipment.level_of_skier}</td>
                                    <td className="p-2 border-r text-xs capitalize">{equipment.age_group}</td>
                                    <td className="p-2 border-r text-xs">{equipment.quantity - equipment.reservedQuantity} / {equipment.quantity}</td>
                                    <td className={`p-2 border-r text-xs ${(equipment.quantity - equipment.reservedQuantity) > 0 ? 'bg-green-100 text-green-600' : 'bg-red-100 text-red-600'}`}>
                                        {(equipment.quantity - equipment.reservedQuantity) > 0 ? "Slobodna" : "Zauzeto"}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                {selectedEquipment && oprema.filter((oprema) => oprema.id === selectedEquipment).map((data) => {
                    return (
                        <div className="mt-3 p-5 rounded-lg border relative">

                            <button onClick={() => setSelectedEquipment(null)} className='p-3 rounded-lg absolute top-3 right-3 bg-red-100 text-red-600 flex items-center gap-3 text-sm'><X size={14} /> Otkaži</button>

                            <h2 className="text-2xl font-semibold mb-4">{data.name}</h2>

                            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
                                <div className="flex flex-col">
                                    <span className="font-medium text-sm opacity-50">Marka:</span>
                                    <h3 className="text-xs">{data.brand}</h3>
                                </div>

                                <div className="flex flex-col">
                                    <span className="font-medium text-sm opacity-50">Tip:</span>
                                    <h3 className="text-xs">{data.type}</h3>
                                </div>

                                <div className="flex flex-col">
                                    <span className="font-medium text-sm opacity-50">Dužina:</span>
                                    <h3 className="text-xs">{data.length_size}</h3>
                                </div>

                                <div className="flex flex-col">
                                    <span className="font-medium text-sm opacity-50">Stanje:</span>
                                    <h3 className="text-xs">{data.condition}</h3>
                                </div>

                                <div className="flex flex-col">
                                    <span className="font-medium text-sm opacity-50">Cijena po danu:</span>
                                    <h3 className="text-xs">{data.price_per_day} EUR</h3>
                                </div>

                                <div className="flex flex-col">
                                    <span className="font-medium text-sm opacity-50">Težina:</span>
                                    <h3 className="text-xs">{data.weight} g</h3>
                                </div>

                                <div className="flex flex-col">
                                    <span className="font-medium text-sm opacity-50">Godina proizvodnje:</span>
                                    <h3 className="text-xs">{data.year_of_manufacture} god.</h3>
                                </div>

                                <div className="flex flex-col">
                                    <span className="font-medium text-sm opacity-50">Nivo skijaša:</span>
                                    <h3 className="text-xs">{data.level_of_skier}</h3>
                                </div>

                                <div className="flex flex-col">
                                    <span className="font-medium text-sm opacity-50">Starosna grupa:</span>
                                    <h3 className="text-xs">{data.age_group}</h3>
                                </div>

                                <div className="flex flex-col">
                                    <span className="font-medium text-sm opacity-50">Količina:</span>
                                    <h3 className="text-xs">{data.quantity}</h3>
                                </div>

                                <div className="flex flex-col">
                                    <span className="font-medium text-sm opacity-50">Rezervisana količina:</span>
                                    <h3 className="text-xs">{data.reservedQuantity}</h3>
                                </div>
                            </div>

                            <div className="mt-6">
                                <h3 className="font-medium text-sm opacity-50">Opis:</h3>
                                <p className="mt-2 opacity-75 text-xs">{data.description}</p>
                            </div>

                            <div className={`${data.reservedQuantity < data.quantity ? 'bg-green-100 text-green-600' : 'bg-red-100 text-red-600'} p-3 rounded-lg text-center mt-6 font-semibold`}>
                                {data.reservedQuantity < data.quantity ? "Dostupno" : "Nedostupno"}
                            </div>

                            {
                                reservationsData.filter((res) => res.equipmentId === data.id).length > 0 && (
                                    <table className='w-full mt-6 border'>
                                        <thead>
                                            <tr className='border-b bg-blue-500 text-white'>
                                                <th style={{ width: '30%' }} className='font-normal p-2 text-xs text-left border-r'>Ime i Prezime</th>
                                                <th style={{ width: '20%' }} className='font-normal p-2 text-xs text-left border-r'>Datum preuzimanja</th>
                                                <th style={{ width: '20%' }} className='font-normal p-2 text-xs text-left border-r'>Datum vraćanja</th>
                                                <th style={{ width: '10%' }} className='font-normal p-2 text-xs text-left border-r'>Trajanje</th>
                                                <th style={{ width: '20%' }} className='font-normal p-2 text-xs text-right border-r'>Iznos</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                reservationsData.filter((res) => res.equipmentId === data.id).map((res) => {
                                                    return (
                                                        <tr className={`border-b ${theme === 'light' ? 'hover:bg-gray-100' : 'hover:bg-[#444]'}`}>
                                                            <td className='font-normal p-2 text-xs text-left border-r'>{res.skierName}</td>
                                                            <td className='font-normal p-2 text-xs text-left border-r'>{res.startDate}</td>
                                                            <td className='font-normal p-2 text-xs text-left border-r'>{res.endDate}</td>
                                                            <td className='font-normal p-2 text-xs text-left border-r'>{res.totalDays} {res.totalDays > 1 ? "dana" : "dan"}</td>
                                                            <td className='font-semibold p-2 text-xs text-right border-r'>{res.totalDays * data.price_per_day} EUR</td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                )
                            }

                        </div>

                    )
                })}

            </div>
        </div>
    );
}

export default Dashboard;
