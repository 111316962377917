import dayjs from 'dayjs';
import React, { useState } from 'react'
import { useTheme } from '../../context/ThemeContext';
import { useAppData } from '../../context/ApplicationContext';

const oprema = [
    {
        id: 1,
        equipmentNumber: 15212,
        name: "Fischer Skije",
        brand: "Fischer",
        type: "Skije",
        length_size: "185",
        condition: "Novo",
        price_per_day: 15,
        weight: 1005,
        year_of_manufacture: "2024",
        level_of_skier: "Srednji",
        age_group: "Dijeca",
        quantity: 5,
        reservedQuantity: 2,
        description: "Savršene skije za srednje skijaše."
    },
    {
        id: 2,
        equipmentNumber: 18172,
        name: "Fischer Pancerice",
        brand: "Fischer",
        type: "Pancerice",
        length_size: "35",
        condition: "Korišteno",
        price_per_day: 7.5,
        weight: 250,
        year_of_manufacture: "2022",
        level_of_skier: "Pocetnik, Srednji, Iskusan",
        age_group: "Odrasli",
        quantity: 15,
        reservedQuantity: 2,
        description: "Udobne pancerice za sve nivoe skijanja."
    },
    {
        id: 3,
        equipmentNumber: 19321,
        name: "Atomic Snowboard",
        brand: "Atomic",
        type: "Snowboard",
        length_size: "150",
        condition: "Novo",
        price_per_day: 20,
        weight: 1800,
        year_of_manufacture: "2024",
        level_of_skier: "Napredni",
        age_group: "Odrasli",
        quantity: 10,
        reservedQuantity: 2,
        description: "Idealno za napredne vozače."
    },
    {
        id: 4,
        equipmentNumber: 19452,
        name: "Leki Štapovi",
        brand: "Leki",
        type: "Štapovi",
        length_size: "120",
        condition: "Novo",
        price_per_day: 5,
        weight: 300,
        year_of_manufacture: "2023",
        level_of_skier: "Svi nivoi",
        age_group: "Dijeca, Odrasli",
        quantity: 2,
        reservedQuantity: 2,
        description: "Lagani i izdržljivi štapovi."
    },
    {
        id: 5,
        equipmentNumber: 20563,
        name: "Nordica Pancerice",
        brand: "Nordica",
        type: "Pancerice",
        length_size: "40",
        condition: "Korišteno",
        price_per_day: 10,
        weight: 1200,
        year_of_manufacture: "2021",
        level_of_skier: "Srednji",
        age_group: "Odrasli",
        quantity: 7,
        reservedQuantity: 2,
        description: "Pancerice sa odličnom podrškom."
    },
    {
        id: 6,
        equipmentNumber: 21684,
        name: "Burton Snowboard",
        brand: "Burton",
        type: "Snowboard",
        length_size: "155",
        condition: "Novo",
        price_per_day: 22,
        weight: 2000,
        year_of_manufacture: "2024",
        level_of_skier: "Svi nivoi",
        age_group: "Dijeca, Odrasli",
        quantity: 12,
        reservedQuantity: 2,
        description: "Prilagođeno za sve nivoe skijaša."
    },
    {
        id: 7,
        equipmentNumber: 22705,
        name: "Salomon Skije",
        brand: "Salomon",
        type: "Skije",
        length_size: "170",
        condition: "Novo",
        price_per_day: 18,
        weight: 900,
        year_of_manufacture: "2023",
        level_of_skier: "Iskusan",
        age_group: "Odrasli",
        quantity: 6,
        reservedQuantity: 2,
        description: "Savršene skije za iskusne skijaše."
    },
    {
        id: 8,
        equipmentNumber: 23826,
        name: "Atomic Pancerice",
        brand: "Atomic",
        type: "Pancerice",
        length_size: "42",
        condition: "Korišteno",
        price_per_day: 8,
        weight: 1150,
        year_of_manufacture: "2020",
        level_of_skier: "Srednji",
        age_group: "Odrasli",
        quantity: 5,
        reservedQuantity: 2,
        description: "Udobne i praktične pancerice."
    },
];

const rentedData = [
    {
        id: 1,
        equipmentId: [
            1,
            3,
            5
        ],
        skierName: "Jovan Mirković",
        phone: "+387 66 415 295",
        startDate: "2024-10-11",
        end_date: "2024-10-14",
        status: "Završeno"
    },
    {
        id: 2,
        equipmentId: 2,
        skierName: "Ana Jovanović",
        phone: "+387 66 415 295",
        startDate: "2024-10-09",
        endDate: "2024-10-11",
        status: "Završeno"
    },
    {
        id: 3,
        equipmentId: 3,
        skierName: "Marko Milenković",
        phone: "+387 66 415 295",
        startDate: "2024-10-11",
        endDate: "2024-10-18",
        status: "U toku"
    }
]

function calculateRentalDays(startDate, endDate) {
    const start = dayjs(startDate);
    const end = dayjs(endDate);
    return end.diff(start, 'day');
}


function calculateLeftDays(endDate) {

    const today = dayjs();
    const end = dayjs(endDate);

    const difference = end.diff(today, 'day');

    return difference > 0
        ? `${difference} ${difference > 1 ? 'dana' : 'dan'} ${difference > 1 ? 'preostalo' : 'preostao'}`
        : "Završeno";
}

function RentanaOprema() {

    const { theme } = useTheme();
    const { rentedEquipment, rentedEquipmentItems, equipment } = useAppData();

    const [rentedDataId, setRentedDataId] = useState(null);


    console.log(rentedEquipment)

    return (
        <div className='p-3'>
            <div className="pretrazi-rentanu-opremu mb-3">
                <input
                    onChange={(e) => setRentedDataId(Number(e.target.value))}
                    type="number"
                    placeholder="Pretraži po broju rezervacije"
                    className={`p-3 text-sm w-full ${theme === 'light' ? 'bg-gray-100' : 'bg-[#444]'}`}
                />

                {
                    rentedDataId ? rentedEquipment.filter((rData) => rData.id === rentedDataId).map((data) => {
                        return (
                            <div key={data.id} className='p-3 bg-gray-50 rounded-lg mt-3 border'>
                                <h3 className="font-semibold text-lg p-3 bg-gray-100 rounded mb-3">Rezervacija #{data?.id}</h3>
                                <div className='grid grid-cols-2 gap-3 mb-3'>
                                    <p className='border-b p-3'><strong>Skijaš:</strong> {data.skier_name}</p>
                                    <div className='border-b p-3'>
                                        <strong className='mr-3'>Oprema:</strong> 
                                        {rentedEquipmentItems.filter((rei) => rei.rented_id === data.id).map((rei) => equipment.filter((oprema) => oprema.id === rei.equipment_id).map((oprema) => `${oprema.name}, `))}
                                    </div>
                                    <p className='border-b p-3'><strong>Telefon:</strong> {data.phone}</p>
                                    <p className='border-b p-3'><strong>Datum Preuzimanja:</strong> {dayjs(data.start_date).format("DD.MM.YYYY")}</p>
                                    <p className='border-b p-3'><strong>Datum Vraćanja:</strong> {dayjs(data.end_date).format("DD.MM.YYYY")}</p>
                                    <p className='border-b p-3'><strong>Ukupan iznos:</strong> {data.price} EUR</p>
                                </div>     
                                <div className={`w-full p-3 rounded bg-gray-200 ${calculateLeftDays(data.end_date) === "Završeno" ? 'bg-green-100 text-green-600' : calculateLeftDays(data.end_date).includes('preostalo') || calculateLeftDays(data.end_date).includes('preostao') ? 'bg-orange-100 text-orange-600' : 'bg-red-100 text-red-600'}`}>
                                   {calculateLeftDays(data.end_date) === "Završeno" ? "Vraćeno i završeno" : calculateLeftDays(data.end_date)}
                                </div>     
                            </div>
                        );
                    }) : null
                }
            </div>
            <table className='w-full border'>
                <thead>
                    <tr className='border-b'>
                        <th className='text-left text-xs p-3 border-r'>ID IZNAJMLJIVANJA</th>
                        <th className='text-left text-xs p-3 border-r'>IME I PREZIME</th>
                        <th className='text-left text-xs p-3 border-r'>MOBITEL</th>
                        <th className='text-left text-xs p-3 border-r'>OPREMA</th>
                        <th className='text-left text-xs p-3 border-r'>DATUM PREUZIMANJA</th>
                        <th className='text-left text-xs p-3 border-r'>DATUM VRACANJA</th>
                        <th className='text-left text-xs p-3 border-r'>UKUPNO DANA</th>
                        <th className='text-left text-xs p-3 border-r'>CIJENA UKUPNO</th>
                        <th className='text-left text-xs p-3 border-r'>STATUS</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        rentedEquipment.map((rData) => {
                            return (
                                <tr className={`border-b ${theme === 'light' ? 'hover:bg-gray-100' : 'hover:bg-[#444]'}`}>
                                    <td className='text-left text-xs p-3 border-r'>{rData.id}</td>
                                    <td className='text-left text-xs p-3 border-r'>{rData.skier_name}</td>
                                    <td className='text-left text-xs p-3 border-r'>{rData.phone}</td>
                                    <td className='text-left text-xs p-3 border-r'>{rentedEquipmentItems.filter((rei) => rei.rented_id === rData.id).map((rei) => equipment.filter((oprema) => oprema.id === rei.equipment_id).map((oprema) => `${oprema.name}, `))}</td>
                                    <td className='text-left text-xs p-3 border-r'>{dayjs(rData.start_date).format("DD.MM.YYYY")}</td>
                                    <td className='text-left text-xs p-3 border-r'>{dayjs(rData.end_date).format("DD.MM.YYYY")}</td>
                                    <td className='text-left text-xs p-3 border-r'>{calculateRentalDays(rData.start_date, rData.end_date)}</td>
                                    <td className='text-left text-xs p-3 border-r'>{rData.price} EUR</td>
                                    <td className={`text-left text-xs p-1 border-r`}>
                                        <span className={`py-2 px-4 rounded-full ${calculateLeftDays(rData.end_date) === "Završeno" ? 'bg-green-100 text-green-600' : calculateLeftDays(rData.end_date).includes('preostalo') || calculateLeftDays(rData.end_date).includes('preostao') ? 'bg-orange-100 text-orange-600' : 'bg-red-100 text-red-600'}`}>{calculateLeftDays(rData.end_date) === "Završeno" ? "Vraćeno i završeno" : calculateLeftDays(rData.end_date)}</span>
                                    </td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        </div>
    )
}

export default RentanaOprema